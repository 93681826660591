(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name linkService
     * @description Service to handle links
     */
    angular.module('services.entities').factory('linkService', linkService);

    linkService.$inject = ['LinksRepo'];
    /* @ngInject*/
    function linkService (LinksRepo) {
        return {
            update: update,
        };

        function update (link, params) {
            return LinksRepo.update(link, params);
        }
    }
})();
