(function () {
    'use strict';

    angular.module('pages.qa.readability').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.domain.qa.readability',
                config: {
                    url: '/readability',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {
                        sidebar: 'quality',
                        title: gettextCatalog.getString('Monsido - Readability'),
                    },
                    resolve: {
                        validateDomain: [
                            '$q',
                            'ng2SessionService',
                            '$state',
                            '$timeout',
                            '$stateParams',
                            'ng2ActiveFeatureService',
                            'ng2MonUIRouterTransitionService',
                            function (
                                $q,
                                ng2SessionService,
                                $state,
                                $timeout,
                                $stateParams,
                                ng2ActiveFeatureService,
                                ng2MonUIRouterTransitionService,
                            ) {
                                var deferred = $q.defer();
                                if (
                                    ng2SessionService.domain.features.readability_test === null ||
                                    !ng2ActiveFeatureService.isFeatureActivePure('admin_readability')
                                ) {
                                    $timeout(function () {
                                        ng2MonUIRouterTransitionService.onTransitionFinished(
                                            () => $state.go('base.customer.domain.qa.summary', $stateParams),
                                        );
                                    });
                                    deferred.resolve(false);
                                } else {
                                    deferred.resolve(true);
                                }
                                return deferred.promise;
                            },
                        ],
                    },
                },
            },
            {
                state: 'base.customer.domain.qa.readability.dashboard',
                config: {
                    url: '',
                    template: '<readability-summary></readability-summary>',
                },
            },
        ];
    }
})();
