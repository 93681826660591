(function () {
    'use strict';

    angular.module('modules.page_fix.pages').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog', 'ng2MonUIRouterTransitionService'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog, ng2MonUIRouterTransitionService) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.domain.page_fix',
                config: {
                    url: '/page-correct',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {
                        features: ['page_fix'],
                        sidebar: 'compliance-shield',
                        title: gettextCatalog.getString('Monsido - PageCorrect'),
                    },
                    resolve: {
                        hasAccess: hasAccess,
                    },
                },
            },
            {
                state: 'base.customer.domain.page_fix.summary',
                config: {
                    url: '',
                    template: '<page-fix-summary></page-fix-summary>',
                    data: {},
                },
            },
            {
                state: 'base.customer.domain.page_fix.index',
                config: {
                    url: '/list?page&page_size&tab&search',
                    template: '<page-fix-list></page-fix-list>',
                    data: { title: gettextCatalog.getString('Monsido - PageCorrect Content') },
                },
            },
            {
                state: 'base.customer.domain.page_fix.checks',
                config: {
                    url: '/checks?search',
                    template: '<page-fix-checks></page-fix-checks>',
                    data: { title: gettextCatalog.getString('Monsido - PageCorrect Accessibility Fixes') },
                },
                resolve: {
                    hasAccessibilityAccess: hasAccessibilityAccess,
                },
            },
            // TODO: Reinstate Accessibility Checks View https://zube.io/monsido/monsido/c/21815
            // {
            //     state: "base.customer.domain.page_fix.accessibility_checks",
            //     config: {
            //         url: "/accessibility-fixes",
            //         abstract: true,
            //         template: "<div ui-view></div>",
            //         data: {
            //             title: gettextCatalog.getString("Monsido - PageCorrect Accessibility fix"),
            //         },
            //         resolve: {
            //             hasAccessibilityAccess: hasAccessibilityAccess,
            //         },
            //     },
            // },
            // {
            //     state: "base.customer.domain.page_fix.accessibility_checks.website_structure",
            //     config: {
            //         url: "/website-structure?search",
            //         template:
            //             "<page-fix-accessibility-checks-website-structure></page-fix-accessibility-checks-website-structure>",
            //         data: {
            //             title: gettextCatalog.getString("Monsido - PageCorrect Accessibility fix Website Structure"),
            //         },
            //     },
            // },
            // {
            //     state: "base.customer.domain.page_fix.accessibility_checks.design",
            //     config: {
            //         url: "/design?search",
            //         template: "<page-fix-accessibility-checks-design></page-fix-accessibility-checks-design>",
            //         data: { title: gettextCatalog.getString("Monsido - PageCorrect Accessibility fix Design") },
            //     },
            // },
            // {
            //     state: "base.customer.domain.page_fix.accessibility_checks.links",
            //     config: {
            //         url: "/links?search",
            //         template: "<page-fix-accessibility-checks-links></page-fix-accessibility-checks-links>",
            //         data: { title: gettextCatalog.getString("Monsido - PageCorrect Accessibility fix Links") },
            //     },
            // },
        ];
    }
    var hasAccess = [
        'ng2ActiveFeatureService',
        '$state',
        '$q',
        '$stateParams',
        'ng2MonUIRouterTransitionService',
        function (ng2ActiveFeatureService, $state, $q, $stateParams, ng2MonUIRouterTransitionService) {
            if (ng2ActiveFeatureService.isFeatureActive('page_fix')) {
                return $q.resolve(true);
            }
            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.domain.dashboard', $stateParams),
            );
        },
    ];

    var hasAccessibilityAccess = [
        'ng2ActiveFeatureService',
        '$state',
        '$q',
        '$stateParams',
        'ng2SessionService',
        'PAGE_CORRECT_DOMAIN_SETTINGS_MODULE',
        'ng2MonUIRouterTransitionService',
        function (
            ng2ActiveFeatureService,
            $state,
            $q,
            $stateParams,
            ng2SessionService,
            PAGE_CORRECT_DOMAIN_SETTINGS_MODULE,
            ng2MonUIRouterTransitionService,
        ) {
            if (ng2ActiveFeatureService.isFeatureActive('accessibility')) {
                var domain = ng2SessionService.domain;
                if (domain && domain.settings) {
                    var checks = domain.settings.scripts.page_correct.enabled_checks;
                    if (checks.indexOf(PAGE_CORRECT_DOMAIN_SETTINGS_MODULE.ACCESSIBILITY_CHECK) > -1) {
                        return $q.resolve(true);
                    }
                }
            }
            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.domain.page_fix.summary', $stateParams),
            );
        },
    ];
})();
