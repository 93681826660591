import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionAccessibilityInformationFastTrack', {
        templateUrl: 'app/components/pagedetails/details/sections/accessibility/information/fast-track/fast-track.html',
        controller: SectionAccessibilityInformationFastTrackController,
        controllerAs: 'vm',
        bindings: {
            sourceCodeId: '<',
        },
    });

    SectionAccessibilityInformationFastTrackController.$inject = [
        'FastTrackService',
        'ng2SessionService',
        '$q',
        'ng2MonEventsService',
        'ng2MonModalService',
        'gettextCatalog',
        '$filter',
        'accessibilityAutofixService',
        'ng2ActiveFeatureService',
        '$deepmerge',
        '$timeout',
    ];
    /* @ngInject */
    function SectionAccessibilityInformationFastTrackController (
        FastTrackService,
        ng2SessionService,
        $q,
        ng2MonEventsService,
        ng2MonModalService,
        gettextCatalog,
        $filter,
        accessibilityAutofixService,
        ng2ActiveFeatureService,
        $deepmerge,
        $timeout,
    ) {
        var vm = this;
        var snippetCharacterLimit = 200;
        vm.$onInit = activate;
        vm.markAsFixed = markAsFixed;
        vm.onMarkAllAsFixed = onMarkAllAsFixed;
        vm.getAffectedPages = getAffectedPages;
        vm.openPages = openPages;
        vm.markCheckAsFixed = markCheckAsFixed;
        vm.toggleCharacterLimit = toggleCharacterLimit;

        function activate () {
            vm.maxCharacters = snippetCharacterLimit;
            vm.showAutofix =
                ng2ActiveFeatureService.isFeatureActive('page_fix_accessibility_check') ||
                ng2ActiveFeatureService.isFeatureActive('page_fix_accessibility_source_code');
            vm.checks = [];
            vm.ignoringAll = false;
            vm.isAllIgnored = false;
            vm.ignoringDone = 0;
            vm.sourceCode = '';
            vm.labels = ['', ''];
            vm.colors = ['#ce2e54', '#dcdcdc'];
            vm.options = {
                cutoutPercentage: 70,
                tooltips: {
                    enabled: false,
                },
            };
            vm.data = null;
            getSummary().then(function () {
                getChecks();
            }, angular.noop);
        }

        function getSummary () {
            return FastTrackService.getFastTrack(vm.sourceCodeId).then(function (data) {
                vm.accessibility = data;
                if (data.source_code) {
                    vm.sourceCode = vm.accessibility.source_code;
                }
            }, $q.reject);
        }

        function getChecks () {
            vm.promise = FastTrackService.getAllChecks(vm.sourceCodeId).then(function (data) {
                vm.isAllIgnored = Array.isArray(data) && data.length === 0;
                var affectedPages = getAffectedPagesCount(data);
                vm.data = [affectedPages, ng2SessionService.domain.crawled_pages - affectedPages];
                if (vm.showAutofix) {
                    return accessibilityAutofixService.parseChecks(data, vm.accessibility).then(function (checks) {
                        vm.checks = checks;
                        updateDropdowns();
                    });
                } else {
                    vm.checks = data;
                    updateDropdowns();
                }
            }, $q.reject);
        }

        function updateDropdowns () {
            for (const check of vm.checks) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Review'),
                        action: ()=>markAsFixed(check, true),
                        eventParams: { action: 'fasttrack-review' },
                        shouldShow: check.result_type === 'review',
                    },
                    {
                        label: gettextCatalog.getString('Ignore'),
                        action: ()=>markAsFixed(check, true),
                        eventParams: { action: 'fasttrack-ignore' },
                        shouldShow: check.result_type === 'error' || check.result_type === 'warning',
                    },
                    {
                        label: gettextCatalog.getString('Mark check as fixed'),
                        action: ()=>markCheckAsFixed(check),
                        eventParams: { action: 'fasttrack-mark-fixed' },
                        shouldShow: check.result_type === 'error' || check.result_type === 'warning',
                    },
                ];

                check.dropdown = dropdown.filter((option)=>option.shouldShow !== false).map(item => {
                    return {
                        label: item.label,
                        action: ()=>{
                            ng2MonEventsService.run('actionTrigger', item.eventParams);

                            item.action();
                        },
                    };
                });
            }
        }

        function markAsFixed (check, update_checks) {
            var confirmText = ' <strong>' + check.name + '</strong>';
            confirmText += '<br /><br />' + gettextCatalog.getString('Add reason (Optional)');

            return ng2MonModalService.prompt(confirmText).then(function (result) {
                var data = $deepmerge({}, check);
                data.comment = result;
                return updateCheck(data).then(function () {
                    if (update_checks === true) {
                        getChecks();
                        getSummary();
                    }
                }, angular.noop);
            });
        }

        function markCheckAsFixed (content) {
            const message = gettextCatalogt.getString('Are you sure you want to mark this check as fixed on all pages?');
            ng2MonModalService.confirm(message).then(()=>{
                FastTrackService.markCheckAsFixed(ng2SessionService.domain.id, vm.accessibility.id, content.id).then(function () {
                    $timeout(function () {
                        getChecks();
                        getSummary();
                    }, 500);
                }, angular.noop);
            });
        }

        function onMarkAllAsFixed (val) {
            vm.ignoringAll = true;
            vm.ignoringDone = 0;
            var result = markAllAsFixed(angular.copy(vm.checks), 0, val);
            result
                .then(
                    function () {},
                    function (errorsCount) {
                        ng2MonModalService.alert(
                            '' +
                                errorsCount +
                                ' ' +
                                gettextCatalog.getString(
                                    'failed when trying to ignore/review all. please try agin, or contact the Monsido team',
                                ),
                        );
                    },
                )
                .finally(function () {
                    getChecks();
                    getSummary();
                    vm.ignoringAll = false;
                });
        }

        function markAllAsFixed (checks, errorsCount, data) {
            if (checks[0]) {
                var head = $deepmerge({}, checks[0]);
                var tail = checks.splice(1);
                head.comment = data;
                return updateCheck(head)
                    .then(
                        function () {},
                        function () {
                            errorsCount = errorsCount + 1;
                        },
                    )
                    .finally(function () {
                        vm.ignoringDone = vm.ignoringDone + 1;
                        if (tail.length > 0) {
                            return markAllAsFixed(tail, errorsCount);
                        } else {
                            return markAllAsFixedDone(errorsCount);
                        }
                    });
            } else {
                return markAllAsFixedDone(errorsCount);
            }
        }

        function markAllAsFixedDone (errorsCount) {
            return $q(function (resolve, reject) {
                if (errorsCount > 0) {
                    reject(errorsCount);
                } else {
                    resolve();
                }
            });
        }

        function getAffectedPagesCount (checks) {
            if (Array.isArray(checks)) {
                return checks.reduce(function (num, check) {
                    return Math.max(check.pages_count, num);
                }, 0);
            }
            return 0;
        }

        function getAffectedPages () {
            var count = getAffectedPagesCount(vm.checks);

            if (count < 10) {
                return gettextCatalog.getString('Less than 10');
            } else if (count < 50) {
                return gettextCatalog.getString('Less than 50');
            } else if (count < 100) {
                return gettextCatalog.getString('Less than 100');
            } else if (count < 1000) {
                return gettextCatalog.getString('More than ') + $filter('number')(count / 1000, 1) + 'k';
            } else {
                return gettextCatalog.getString('More than ') + $filter('numeraljs')(count, '0a');
            }
        }

        function openPages (check) {
            var params = {
                body: 'fastTrackDialogsPages',
                size: 'lg',
                data: {
                    accessibilitySourceCodeId: vm.sourceCodeId,
                    check: check,
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: getChecks });
        }

        function updateCheck (check) {
            return FastTrackService.updateCheck(vm.sourceCodeId, check);
        }

        function toggleCharacterLimit () {
            if (vm.maxCharacters > 0) {
                vm.maxCharacters = 0;
            } else {
                vm.maxCharacters = snippetCharacterLimit;
            }
        }
    }
})();
