(function () {
    'use strict';

    angular.module('pages.admin.labels').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.admin.labels',
                config: {
                    url: '/labels',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: { title: gettextCatalog.getString('Monsido - Labels') },
                },
            },
            {
                state: 'base.customer.admin.labels.index',
                config: {
                    url: '',
                    template: '<admin-labels></admin-labels>',
                    data: {},
                },
            },
        ];
    }
})();
