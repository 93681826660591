import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.inventory.content.documents').component('inventoryDocument', {
        templateUrl: 'app/pages/inventory/content/documents/documents.html',
        controller: InventoryDocumentController,
        controllerAs: 'vm',
    });

    InventoryDocumentController.$inject = ['gettextCatalog', 'LinksRepo', '$q', '$stateParams', 'ng2MonEventsService', '$filter'];

    function InventoryDocumentController (gettextCatalog, LinksRepo, $q, $stateParams, ng2MonEventsService, $filter) {
        var vm = this;
        vm.getPage = getPage;
        vm.openPages = openPages;
        vm.$onInit = activate;

        function activate () {
            var target = $stateParams['doc-target'];
            vm.domain_id = $stateParams.domainId;
            vm.types = {
                pages: {
                    type: 'link',
                    name: gettextCatalog.getString('Pages'),
                    icon: 'fas fa-copy',
                    total: 0,
                    active: 'link',
                },
                spreadsheet: {
                    type: 'spreadsheet',
                    name: gettextCatalog.getString('Spreadsheets'),
                    icon: 'fas fa-file-excel',
                    total: 0,
                    active: 'spreadsheet',
                },
                pdf: {
                    type: 'pdf',
                    name: gettextCatalog.getString('PDF files'),
                    icon: 'fas fa-file-pdf',
                    total: 0,
                    active: 'pdf',
                },
                zip: {
                    type: 'archive',
                    name: gettextCatalog.getString('Archive files'),
                    icon: 'fas fa-file-archive',
                    total: 0,
                    active: 'archive',
                },
                document: {
                    type: 'document',
                    name: gettextCatalog.getString('Text documents'),
                    icon: 'fas fa-file-alt',
                    total: 0,
                    active: 'document',
                },
                slide: {
                    type: 'slide',
                    name: gettextCatalog.getString('Slides'),
                    icon: $filter('monIcons')(['TABS', 'PAGES', 'SLIDE']),
                    total: 0,
                    active: 'slide',
                },
                file: {
                    type: 'file',
                    name: gettextCatalog.getString('Other files'),
                    icon: 'fas fa-file',
                    total: 0,
                    active: 'file',
                },
            };
            vm.exports = [
                {
                    name: gettextCatalog.getString('Inventory Documents Export'),
                    data: {
                        category: 'inventory_documents',
                    },
                },
                {
                    name: gettextCatalog.getString('Links To Documents Export'),
                    data: {
                        category: 'inventory_links_to_documents',
                    },
                },
            ];
            vm.documentCollection = ['link', 'spreadsheet', 'pdf', 'archive', 'document', 'slide', 'file'];

            getPage();
            if (target && vm.documentCollection.indexOf(target) > -1) {
                openPages(vm.types[target]);
            }
        }

        function getPage () {
            var promises = [];
            vm.documentCollection.forEach(function (documentType) {
                promises.push(getLinks(documentType));
            });
            vm.loading = true;

            $q.all(promises).then(function (values) {
                vm.types.pages.total = values[0].total;
                vm.types.spreadsheet.total = values[1].total;
                vm.types.pdf.total = values[2].total;
                vm.types.zip.total = values[3].total;
                vm.types.document.total = values[4].total;
                vm.types.slide.total = values[5].total;
                vm.types.file.total = values[6].total;

                vm.loading = false;
            }, angular.noop);
        }

        function getLinks (type) {
            var params = {
                page: 1,
                page_size: 1,
                domainId: vm.domain_id,
                type: type,
                status: 'active',
                page_fixed: false,
            };

            return LinksRepo.getAll(params);
        }

        function openPages (type) {
            var params = {};
            if (type.type === 'link') {
                params = {
                    body: 'inventoryDialogsContentLinks',
                    size: 'lg',
                };
            } else {
                params = {
                    body: 'pagesFiles',
                    size: 'lg',
                    data: {
                        lookFor: type.active,
                        type: type,
                        domainId: vm.domain_id,
                        icon: type.icon,
                        status: 'active',
                    },
                };
            }

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }
    }
})();
