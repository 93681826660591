(function () {
    'use strict';

    angular.module('modules.page_fix.forms').component('pageFixFormBrokenLink', {
        templateUrl: 'app/modules/page-fix/forms/broken-link/broken-link.html',
        controller: AutofixBrokenLinkController,
        controllerAs: 'vm',
        bindings: {
            resolve: '<',
            close: '&',
            dismiss: '&',
        },
    });

    AutofixBrokenLinkController.$inject = [
        'LinksRepo',
        'PageFixBrokenLinkService',
        'ng2PageFixRepoService',
        'PAGE_FIX_FIXABLE_TYPES',
    ];
    /* @ngInject */
    function AutofixBrokenLinkController (LinksRepo, PageFixBrokenLinkService, ng2PageFixRepoService, PAGE_FIX_FIXABLE_TYPES) {
        var vm = this;
        vm.$onInit = activate;
        vm.submit = submit;
        vm.cancel = cancel;

        function activate () {
            vm.loading = true;
            vm.urls = [];
            vm.linkReplacement = '';
            vm.service = new PageFixBrokenLinkService();
            vm.service.setModel(vm.resolve.model);
            vm.service.setPageFix(vm.resolve.pageFix);
            vm.service.setFixableId(vm.resolve.typeId || vm.resolve.model.id);
            vm.linkReplacement = vm.service.getLinkReplacement();
            getOriginalUrls()
                .then(function (urls) {
                    vm.urls = urls;
                    vm.service.parseQuerySelector(urls);
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });
        }

        function submit () {
            var promise;
            var change = {
                value: vm.linkReplacement,
                attribute: vm.service.getAttribute(),
            };
            var selector = vm.service.getSelector();
            var pageFix = vm.service.getPageFix();
            if (pageFix) {
                promise = ng2PageFixRepoService.update(pageFix, { change: change, selector: selector });
            } else {
                promise = ng2PageFixRepoService.create({
                    change: change,
                    selector: selector,
                    fixable_type: PAGE_FIX_FIXABLE_TYPES.link,
                    fixable_id: vm.service.getFixableId(),
                    change_type: vm.service.getChangeType(),
                });
            }

            promise.then(function (pageFix) {
                closeModal(pageFix);
            });
        }

        function cancel () {
            vm.dismiss();
        }

        // PROTECTED

        function closeModal (result) {
            vm.close({ $value: result });
        }

        function getOriginalUrls () {
            return LinksRepo.getOriginalUrls({
                id: vm.service.getFixableId(),
            });
        }
    }
})();
