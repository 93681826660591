(function () {
    'use strict';

    angular.module('pages.pages').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.domain.pages',
                config: {
                    url: '/pages',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {},
                    resolve: {
                        hasAccess: hasAccess,
                    },
                },
            },
            {
                state: 'base.customer.domain.pages.index',
                config: {
                    url: '?page&sort_by&sort_dir&&search&page_size&status&type',
                    template: '<pages-overview></pages-overview>',
                    data: { title: gettextCatalog.getString('Monsido - Prioritized Content') },
                },
            },
        ];
    }

    var hasAccess = [
        'ng2ActiveFeatureService',
        '$q',
        '$state',
        '$stateParams',
        'ng2MonUIRouterTransitionService',
        function (ng2ActiveFeatureService, $q, $state, $stateParams, ng2MonUIRouterTransitionService) {
            if (ng2ActiveFeatureService.isFeatureActivePure('prioritized_content_all')) {
                return $q.resolve(true);
            }
            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.domain.dashboard', $stateParams),
            );
        },
    ];
})();
