(function () {
    'use strict';

    angular.module('modules.data_privacy.pages').component('privacyModule', {
        templateUrl: 'app/modules/data-privacy/pages/module/module.html',
        controller: DataPrivacyModuleController,
        controllerAs: 'vm',
    });

    DataPrivacyModuleController.$inject = [
        'ng2MonModalService',
        'DataPrivacyService',
        'gettextCatalog',
        'ng2MonEventsService',
        'ng2ToastService',
        '$localStorage',
    ];
    /* @ngInject */
    function DataPrivacyModuleController (
        ng2MonModalService,
        DataPrivacyService,
        gettextCatalog,
        ng2MonEventsService,
        toastService,
        $localStorage,
    ) {
        var vm = this;
        vm.requestConfirmBox = requestConfirmBox;
        vm.$onInit = active;

        function active () {
            vm.requestSent = $localStorage.dpm_request_sent || false;
        }

        function requestConfirmBox () {
            var text = gettextCatalog.getString(
                'More info about Monsido Data Privacy <br><br> Please press ok if you want to hear more, and we will have someone reach out to you.',
                { newLine: '<br/>' },
            );

            ng2MonModalService.confirm(text).then(dataPrivacyRequestInfo, angular.noop);
        }

        function dataPrivacyRequestInfo () {
            vm.saving = true;
            ng2MonEventsService.run('actionTrigger', { action: 'data-privacy-request-more-info' });
            DataPrivacyService.requestDataPrivacySupport(gettextCatalog.getString('Contact me with more info about Data Privacy'))
                .then(function () {
                    toastService.success(gettextCatalog.getString('Thanks! Our team has been notified of your request.'));
                    $localStorage.dpm_request_sent = true;
                    vm.requestSent = true;
                }, angular.noop)
                .finally(function () {
                    vm.saving = false;
                });
        }
    }
})();
