import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { MonImgMetadataOverlayComponent } from '@monsido/ng2/modules/inventory/components/img-metadata-overlay/img-metadata-overlay.component';

(function () {
    'use strict';

    angular.module('pages.inventory.content.links').component('inventoryContentImages', {
        templateUrl: 'app/pages/inventory/content/images/images.html',
        controller: LinksController,
        controllerAs: 'vm',
    });

    LinksController.$inject = [
        'LinksRepo',
        '$controller',
        '$stateParams',
        'Dialog',
        'ng2MonEventsService',
        'gettextCatalog',
        '$filter',
        'ng2ActiveFeatureService',
    ];

    function LinksController (LinksRepo, $controller, $stateParams, Dialog, ng2MonEventsService, gettextCatalog, $filter, ng2ActiveFeatureService) {
        var vm = this;
        vm.getPage = getPage;
        vm.openDialog = openDialog;
        vm.$onInit = activate;
        vm.openMetadataModal = openMetadataModal;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.links = [];
            vm.search = null;
            vm.domain_id = $stateParams.domainId;
            vm.subheaderTranslation = '';
            vm.hasAccessToMetaDataOverlay = ng2ActiveFeatureService.isFeatureActive('image_metadata');

            setupTabs();
            getPage();
        }

        function setupTabs () {
            vm.tabs = [
                {
                    name: gettextCatalog.getString('Internal'),
                    value: 'internal',
                },
                {
                    name: gettextCatalog.getString('External'),
                    value: 'external',
                },
            ];
            vm.activeTab = vm.tabs[0].value;
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search,
                type: 'image',
                relation: vm.activeTab,
                status: 'active',
                page_fixed: false,
            };

            vm.promise = LinksRepo.getAll(params).then(function (data) {
                vm.links = data;
                getSubHeaderDescription();
            }, angular.noop);
        }

        function getSubHeaderDescription () {
            vm.subheaderTranslation = gettextCatalog.getPlural(
                $filter('monNumber')(vm.links.total, '0,0'),
                '1 result',
                '{{$count}} results',
                {},
            );
        }

        function openDialog (link, type) {
            var params = {
                body: 'pagesFilesPages',
                size: 'lg',
                data: { link: link, domainId: vm.domain_id, active: 'image', icon: 'fas fa-file-image', type: type },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function openMetadataModal (link) {
            var params = {
                component: MonImgMetadataOverlayComponent,
                data: { linkUrl: link.url, linkId: link.id },
                dialogOptions: {
                    size: 'md',
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_NG2_DIALOG, params);
        }
    }
})();
