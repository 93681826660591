import { Injectable } from '@angular/core';
import { TranslateService } from '@client/app/services/translate/translate.service';

type GuidelineInfoType = Record<string, {
    title: string,
    name: string,
}>;

const ids = [
    'WCAG2-AAA',
    'WCAG2-AA',
    'WCAG2-A',
    'WCAG21-AAA_mobile',
    'WCAG21-AA_mobile',
    'WCAG21-A_mobile',
    'WCAG21-AAA',
    'WCAG21-AA',
    'WCAG21-A',
    'WCAG22-AAA',
    'WCAG22-AA',
    'WCAG22-A',
    'WCAG22-AAA_mobile',
    'WCAG22-AA_mobile',
    'WCAG22-A_mobile',
];
const titles = [
    'WCAG 2.0 AAA',
    'WCAG 2.0 AA',
    'WCAG 2.0 A',
    'WCAG 2.1 AAA Mobile',
    'WCAG 2.1 AA Mobile',
    'WCAG 2.1 A Mobile',
    'WCAG 2.1 AAA',
    'WCAG 2.1 AA',
    'WCAG 2.1 A',
    'WCAG 2.2 AAA',
    'WCAG 2.2 AA',
    'WCAG 2.2 A',
    'WCAG 2.2 AAA Mobile',
    'WCAG 2.2 AA Mobile',
    'WCAG 2.2 A Mobile',
];

@Injectable({
    providedIn: 'root',
})
export class GuidelineInfoService {

    wcag: GuidelineInfoType;
    qw: GuidelineInfoType;
    currentWCAGGuidelineVersion = 5;
    currentQWGuidelineVersion = 1;

    constructor (
        private translateService: TranslateService,
    ) {

        const names = [
            this.translateService.getString('WCAG 2.0 (Level A)'),
            this.translateService.getString('WCAG 2.0 (Level AA)'),
            this.translateService.getString('WCAG 2.0 (Level AAA)'),
            this.translateService.getString('WCAG 2.1 (Level A) Mobile'),
            this.translateService.getString('WCAG 2.1 (Level AA) Mobile'),
            this.translateService.getString('WCAG 2.1 (Level AAA) Mobile'),
            this.translateService.getString('WCAG 2.1 (Level A)'),
            this.translateService.getString('WCAG 2.1 (Level AA)'),
            this.translateService.getString('WCAG 2.1 (Level AAA)'),
            this.translateService.getString('WCAG 2.2 (Level A)'),
            this.translateService.getString('WCAG 2.2 (Level AA)'),
            this.translateService.getString('WCAG 2.2 (Level AAA)'),
            this.translateService.getString('WCAG 2.2 (Level A) Mobile'),
            this.translateService.getString('WCAG 2.2 (Level AA) Mobile'),
            this.translateService.getString('WCAG 2.2 (Level AAA) Mobile'),
        ];

        if (!(ids.length === titles.length && titles.length === names.length)) {
            throw new Error('Wrong data arrays!');
        }

        this.wcag = {};

        // Yes, this is an EXTREMELY bad design, from my point of view.
        // But we are forced to do that by SQ "duplicate code" rules.
        ids.forEach((id, k) => {
            this.wcag[id] = {
                title: titles[k],
                name: names[k],
            };
        });

        this.qw = {
            'QUALWEB-A': {
                title: 'QualWeb A',
                name: this.translateService.getString('QualWeb (Level A)'),
            },
            'QUALWEB-AA': {
                title: 'QualWeb AA',
                name: this.translateService.getString('QualWeb (Level AA)'),
            },
        };
    }
}
