import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';
import { forkJoin, of } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';

(function () {
    'use strict';

    angular.module('pages.domain').component('domainDashboard', {
        templateUrl: 'app/pages/domain/dashboard.html',
        controller: DashboardController,
        controllerAs: 'vm',
    });

    DashboardController.$inject = [
        'ng2CrawlsRepoService',
        'ng2SessionService',
        '$stateParams',
        'ng2MonEventsService',
        'ng2PageRepoService',
        'MON_MODULES',
        'LinksRepo',
        'pageService',
        'ng2CoreLoginService',
        'ng2ActiveFeatureService',
        'ng2GlobalHelperService',
    ];

    /* @ngInject */
    function DashboardController (
        ng2CrawlsRepoService,
        ng2SessionService,
        $stateParams,
        ng2MonEventsService,
        ng2PageRepoService,
        MON_MODULES,
        LinksRepo,
        pageService,
        ng2CoreLoginService,
        ng2ActiveFeatureService,
        ng2GlobalHelperService,
    ) {
        var vm = this;
        let canceler$;

        vm.$onInit = activate;
        vm.$onDestroy = onDestroy;
        vm.domainScanNearLimit = domainScanNearLimit;

        function activate () {
            vm.domain = null;
            vm.domainGroup = null;
            vm.showNotifications = ng2ActiveFeatureService.isFeatureActive('dashboard_important_notifications');
            vm.showPolicy = ng2ActiveFeatureService.isFeatureActivePure('policies_all');
            vm.showSEO = ng2ActiveFeatureService.isFeatureActivePure('seo_all');
            vm.showQA = ng2ActiveFeatureService.isFeatureActivePure('qa_spellcheck') || ng2ActiveFeatureService.isFeatureActivePure('qa_links');
            vm.customer = ng2SessionService.customer;
            vm.showHeartbeat = vm.customer !== null && vm.customer.plan_traits.heartbeat;
            vm.domainItemCount = 0;
            vm.showAccessibility = false;
            vm.showStatistics = false;
            vm.showDataPrivacy = false;
            vm.authUser = null;
            vm.modules = MON_MODULES;

            if ($stateParams.page_id) {
                openPage();
            }

            canceler$ = ng2GlobalHelperService.getSubject();

            vm.authUser = null;
            ng2CoreLoginService.getUser().then(function (user) {
                vm.authUser = user;
            }, angular.noop);

            forkJoin([
                getCrawls(),
                getPages('html'),
                getPages('document'),
                getPages('pdf'),
                getLinks(),
            ])
                .pipe(
                    takeUntil(canceler$),
                    catchError(() => of([])),
                )
                .subscribe({
                    next: values => {
                        setupDomain();

                        const [crawls, htmlPages, documentPages, pdfPages, links] = values;

                        vm.historyData = crawls;
                        vm.pagesCount = parseInt(htmlPages.total);
                        vm.documentsCount = parseInt(documentPages.total);
                        vm.pdfsCount = parseInt(pdfPages.total);
                        vm.totalContentCount = vm.pagesCount + vm.documentsCount + vm.pdfsCount;
                        vm.linksCount = parseInt(links.total);

                        if (vm.historyData && vm.historyData[0]) {
                            vm.domainItemCount = vm.historyData[0].page_count;

                            if (vm.domain.scan.scan_documents) {
                                vm.domainItemCount += vm.historyData[0].documents_count;
                            }
                        }
                    },
                });
        }

        function onDestroy () {
            canceler$.next();
            canceler$.complete();
        }

        function getCrawls () {
            var params = {
                page_size: 6,
            };

            return ng2CrawlsRepoService.getAllObservable(params, canceler$);
        }

        function getPages (type) {
            var params = {
                page_size: 1,
                page: 1,
                'types[]': type,
            };

            return ng2PageRepoService.getAllObservable(params, canceler$);
        }

        function getLinks () {
            var params = {
                page_size: 1,
                page: 1,
                page_fixed: false,
            };

            return LinksRepo.getAllObservable(params, canceler$);
        }

        function openPage () {
            pageService.get($stateParams.page_id).then(function (page) {
                var data = {
                    page: page,
                };

                var params = {
                    body: 'pageDetails',
                    size: 'lg',
                    classes: 'page-details-app',
                    data: data,
                };

                ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
            }, angular.noop);
        }

        function domainScanNearLimit () {
            if (!vm.customer || !vm.domain) {
                return false;
            }

            return vm.domainItemCount >= vm.customer.plan_traits.max_scanned_pages;
        }

        function setupDomain () {
            vm.domain = ng2SessionService.domain;
            vm.domainGroup = ng2SessionService.domainGroup;
            vm.showAccessibility = ng2ActiveFeatureService.isFeatureActive(MON_MODULES.accessibility);
            vm.showStatistics = ng2ActiveFeatureService.isFeatureActive(MON_MODULES.statistics);
            vm.showDataPrivacy = ng2ActiveFeatureService.isFeatureActive(MON_MODULES.data_privacy);
            vm.domainItemCount = vm.domain.crawled_pages || 0;

            if (vm.domainGroup === null && vm.domain.scan.scan_documents) {
                var documentCount = 0;
                if (vm.domain.crawl_history) {
                    documentCount = parseInt(vm.domain.crawl_history.documents_count, 10) || 0;
                }
                vm.domainItemCount = vm.domainItemCount + documentCount;
            } else if (vm.domainGroup !== null) {
                vm.domainItemCount = parseInt(vm.domainGroup.page_count, 10) || 0;
            }
        }
    }
})();
