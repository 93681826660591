(function () {
    'use strict';
    /**
     * @memberof services.entities
     * @ngdoc factory
     * @name pageService
     * @description Service to handle Page requests
     */
    angular.module('services.entities').factory('pageService', pageService);

    pageService.$inject = [
        'ENTITY_CONSTANT',
        'ng2PageRepoService',
        'ng2DemoHasAccessService',
        'ng2DemoPageRepoService',
        'ng2DemoPdfRepoService',
        'ng2GlobalHelperService',
        'PagePageLinksRepo',
        'ng2PageAccessibilityRepoService',
        'PageNinRepo',
        'ng2PagePolicyRepoService',
        'ng2MonEventsService',
        'ng2PageClarityRepoService',
        'PageSearchRepo',
    ];

    /* @ngInject*/
    function pageService (
        ENTITY_CONSTANT,
        ng2PageRepoService,
        ng2DemoHasAccessService,
        ng2DemoPageRepoService,
        ng2DemoPdfRepoService,
        ng2GlobalHelperService,
        PagePageLinksRepo,
        ng2PageAccessibilityRepoService,
        PageNinRepo,
        ng2PagePolicyRepoService,
        ng2MonEventsService,
        ng2PageClarityRepoService,
        PageSearchRepo,
    ) {
        return {
            get,
            update,
            updateLink,
            getStatSummary,
            getStatVisitors,
            updateAccessibilityError,
            deleteAccessibilityError,
            updateAccessibilityCheck,
            deleteAccessibilityCheck,
            updateAccessibilityCheckErrors,
            updateClarityCheckError,
            updateNin,
            updatePolicy,
            destroyPolicy,
            updateSearch,
            updateSeoIssue,
            rescanPageDetail,
            getCmsUrlPageDetail,
        };

        /**
         * @memberOf pageService
         * @desc Update page link
         * @param pageId
         * @param linkId
         * @param data - data object to add to the request
         * @param params
         * @returns {Promise} - Promise containing the updated link
         */
        function updateLink (pageId, linkId, data, params) {
            return PagePageLinksRepo.update(pageId, linkId, data, params);
        }

        /**
         * @memberOf pageService
         * @desc Get the Page Entity
         * @param {object|number} page - Id of the active page
         * @param {object} params - The parameters to inject to the get
         * @param {object} config - The configuration
         * @returns {Promise} - Promise containing the requested page
         */
        function get (page, params, config) {
            var isObject = ng2GlobalHelperService.isObject(page);
            if (isObject && ng2DemoHasAccessService.canAccessAccessibilityPDF() && isDemo(page)) {
                return ng2DemoPageRepoService.get(page.id);
            }

            var pageId = isObject ? page.id : page;
            var pagePromise = ng2PageRepoService.get(pageId, params, config);

            pagePromise.then(function (pageObj) {
                ng2MonEventsService.run(ENTITY_CONSTANT.PAGE_UPDATED_EVENT, pageObj);
            }, angular.noop);

            return pagePromise;
        }

        /**
         * @memberOf pageService
         * @desc Update the Page Entity
         * @param {object} page - Object of the active page
         * @param {object} data - The parameters to inject
         * @param {object} params - The query parameters to inject
         * @param {object} config - The configuration
         * @returns {Promise} - Promise containing the new Page object
         */
        function update (page, data, params, config) {
            if (ng2DemoHasAccessService.canAccessAccessibilityPDF() && isDemo(page)) {
                return ng2DemoPageRepoService.update(page.id);
            }

            return ng2PageRepoService.update(page.id, data, params, config).then(function (pageObj) {
                ng2MonEventsService.run('updateRow', ENTITY_CONSTANT.PAGE, pageObj);
                return pageObj;
            });
        }

        /**
         * @memberOf pageService
         * @desc Get statistics summary data
         * @param {number} pageId - The page id
         * @param {object} params - The parameters to inject
         * @param {object} config - The configuration
         * @returns {Promise} - Promise containing the Statistics Summary collection
         */
        function getStatSummary (pageId, params, config) {
            if (ng2DemoHasAccessService.useStatisticsDemoData()) {
                return ng2DemoPageRepoService.getStatSummary(pageId);
            }

            return ng2PageRepoService.getStatSummary(pageId, params, config);
        }

        /**
         * @memberOf pageService
         * @desc Get Statistics visitors data
         * @param {number} pageId - The page id
         * @param {object} params - The parameters to inject
         * @param {object} config - The configuration
         * @returns {Promise} - Promise containing the visitor collection
         */
        function getStatVisitors (pageId, params, config) {
            if (ng2DemoHasAccessService.useStatisticsDemoData()) {
                return ng2DemoPageRepoService.getStatVisitors(pageId);
            }

            return ng2PageRepoService.getStatVisitors(pageId, params, config);
        }

        function updateAccessibilityError (pageId, error, params) {
            return ng2PageAccessibilityRepoService.updateError(pageId, error, params);
        }

        function deleteAccessibilityError (pageId, error) {
            return ng2PageAccessibilityRepoService.markIssueAsFixed(pageId, error);
        }

        function updateAccessibilityCheck (pageId, checkId, params) {
            return ng2PageAccessibilityRepoService.updateCheck(pageId, checkId, params);
        }

        function updateAccessibilityCheckErrors (pageId, check) {
            return ng2PageAccessibilityRepoService.updateCheckErrors(pageId, check);
        }

        function deleteAccessibilityCheck (pageId, checkId) {
            return ng2PageAccessibilityRepoService.markCheckAsFixed(pageId, checkId);
        }

        function updateClarityCheckError (pageId, checkId, errorId, data, params, config) {
            return ng2PageClarityRepoService.updateCheckError(pageId, checkId, errorId, data, params, config);
        }

        function updateNin (pageId, niNumberId, data, params) {
            return PageNinRepo.update(pageId, niNumberId, data, params);
        }

        function updatePolicy (pageId, data, params) {
            return ng2PagePolicyRepoService.update(pageId, data, params);
        }

        function destroyPolicy (pageId, id) {
            return ng2PagePolicyRepoService.destroy(pageId, id);
        }

        function updateSearch (pageId, params, config) {
            return PageSearchRepo.update(pageId, params, config);
        }

        function updateSeoIssue (pageId, issueId, data, params, headers) {
            return ng2PageRepoService.updateSeoIssue(pageId, issueId, data, params, headers);
        }

        function rescanPageDetail (pageId, data, params, config) {
            return ng2PageRepoService.rescan(pageId, data, params, config);
        }

        function getCmsUrlPageDetail (pageId, params, config) {
            return ng2PageRepoService.getCmsUrl(pageId, params, config);
        }

        // PROTECTED

        function isDemo (page) {
            return ng2DemoPageRepoService.isDemo(page) || ng2DemoPdfRepoService.isDemo(page);
        }
    }
})();
