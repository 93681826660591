(function () {
    'use strict';
    /**
     * @memberof modules.data_privacy.services.api
     * @ngdoc factory
     * @name DataPrivacyEntitiesContentService
     * @description Service to handle Data Privacy Content Data
     */
    angular
        .module('modules.data_privacy.services.api')
        .factory('DataPrivacyEntitiesContentService', DataPrivacyEntitiesContentService);

    DataPrivacyEntitiesContentService.$inject = ['DataPrivacyContentRepo'];
    /* @ngInject*/
    function DataPrivacyEntitiesContentService (DataPrivacyContentRepo) {
        return {
            getAll: getAll,
        };

        /**
         * @memberOf DataPrivacyEntitiesContentService
         * @desc Get Content Data
         * @param {Object} params - Query Parameters
         * @param {Number} config - $http provider configuration
         */
        function getAll (params, config) {
            return DataPrivacyContentRepo.getAll(params, config);
        }
    }
})();
