(function () {
    'use strict';
    angular
        .module('modules.page_fix.services')
        .factory('PageFixAccessibilityConfigService', PageFixAccessibilityConfigService);

    PageFixAccessibilityConfigService.$inject = [
        'PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG',
        '$deepmerge',
        'Lodash',
        'gettextCatalog',
    ];

    /* @ngInject*/
    function PageFixAccessibilityConfigService (
        PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG,
        $deepmerge,
        Lodash,
        gettextCatalog,
    ) {
        var vm = this;
        vm.config = $deepmerge({}, PAGE_FIX_ACCESSIBILITY_CHECK_CONFIG);
        vm.descriptionTranslation = {};
        vm.checkImgIds = [1, 2, 3, 4, 5, 6, 7, 11, 16, 178];

        function init () {
            Lodash.forEach(vm.config, function (config, key) {
                if (config.check_description) {
                    vm.descriptionTranslation[key] = gettextCatalog.getString(config.check_description);
                }
            });

            return {
                getConfig: getConfig,
                isCheckImg: isCheckImg,
                isPageFixImg: isPageFixImg,
                getDescription: getDescription,
            };
        }

        return init();
        // ///////////////

        function getConfig () {
            return vm.config;
        }

        function isCheckImg (check) {
            return vm.checkImgIds.indexOf(check.id) > -1;
        }

        function isPageFixImg (pageFix) {
            return vm.checkImgIds.indexOf(pageFix.change.check_id) > -1;
        }

        function getDescription (check) {
            return vm.descriptionTranslation[check.id];
        }
    }
})();
