(function () {
    'use strict';

    angular.module('pages.accessibility').component('accessibilityPdfExternal', {
        templateUrl: 'app/pages/accessibility/pdf/external/external.html',
        controller: AccessibilityPdfExternalController,
        controllerAs: 'vm',
    });

    AccessibilityPdfExternalController.$inject = ['LinksRepo', '$controller', 'gettextCatalog', 'linkService'];

    function AccessibilityPdfExternalController (LinksRepo, $controller, gettextCatalog, linkService) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.setReview = setReview;
        vm.tabChange = tabChange;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.search = '';
            vm.status = false;
            vm.tabHeader = gettextCatalog.getString('All Pending External PDF files');
            vm.tabSubHeader = gettextCatalog.getString(
                'A list of all external PDF files that you need to check for accessibility issues',
            );
            vm.currentSort = { by: 'text', direction: 'desc' };

            setupTabs();
            getPage();
        }

        function setupTabs () {
            vm.tabs = [
                {
                    name: gettextCatalog.getString('Pending'),
                    value: false,
                },
                {
                    name: gettextCatalog.getString('Reviewed/Ok'),
                    value: true,
                },
            ];
        }

        function getPage () {
            var params = {
                status: 'active',
                relation: 'external',
                type: 'pdf',
                page_size: vm.pageSize,
                page: vm.page,
                accessibility_reviewed: vm.status,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                search: vm.search || null,
                page_fixed: false,
            };
            vm.progress = LinksRepo.getAll(params).then(function (links) {
                vm.pdfs = links;
            }, angular.noop);
        }

        function setReview (pdf) {
            var params = {
                accessibility_reviewed: !pdf.accessibility_reviewed,
                id: pdf.id,
            };

            linkService.update(params).then(function () {
                getPage();
            }, angular.noop);
        }

        function tabChange (status) {
            vm.status = status;
            getPage();
            if (vm.status) {
                vm.tabHeader = gettextCatalog.getString('All Reviewed External PDF files');
                vm.tabSubHeader = gettextCatalog.getString(
                    'A list of all external PDF files already reviewed for accessibility issues',
                );
            } else {
                vm.tabHeader = gettextCatalog.getString('All Pending External PDF files');
                vm.tabSubHeader = gettextCatalog.getString(
                    'A list of all external PDF files that you need to check for accessibility issues',
                );
            }
        }
    }
})();
