(function () {
    'use strict';

    angular.module('pages.inventory.content').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog', 'ng2SessionService'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog, ng2SessionService) {
        routerHelper.configureStates(getStates(gettextCatalog, ng2SessionService));
    }

    function getStates (gettextCatalog, ng2SessionService) {
        return [
            {
                state: 'base.customer.domain.inventory.content',
                config: {
                    url: '',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {
                        sidebar: 'inventory',
                    },
                },
            },
            {
                state: 'base.customer.domain.inventory.content.pages',
                config: {
                    url: '/pages',
                    template:
                        '<inventory-content-pages icon=\'fas fa-window-maximize\' headline="\'HTML Pages\' | translate" api-types=\'["html"]\' mon-export=\'inventory_html_pages\'></inventory-content-pages>',
                    data: { title: gettextCatalog.getString('Monsido - HTML Pages') },
                },
            },
            {
                state: 'base.customer.domain.inventory.content.documents',
                config: {
                    url: '/documents',
                    controllerAs: 'vm',
                    controller: [
                        function () {
                            this.scanDocument = ng2SessionService.domain && ng2SessionService.domain.scan.scan_documents;
                        },
                    ],
                    template:
                        '<inventory-content-pages ng-if=\'vm.scanDocument\' icon=\'fas fa-file-alt\' headline="\'Documents\' | translate" selected-tabs=\'documents\' api-types=\'["pdf", "document"]\' mon-export=\'inventory_document_pages,pages_documents,links_to_documents\'></inventory-content-pages>' +
                        '<inventory-content-documents-no-scan ng-if=\'!vm.scanDocument\'></inventory-content-documents-no-scan>',
                    data: { title: gettextCatalog.getString('Monsido - Documents') },
                },
            },
            {
                state: 'base.customer.domain.inventory.content.links',
                config: {
                    url: '/links?doc-target',
                    template: '<inventory-document></inventory-document>',
                    data: { title: gettextCatalog.getString('Monsido - Links') },
                },
            },
            {
                state: 'base.customer.domain.inventory.content.images',
                config: {
                    url: '/images',
                    template: '<inventory-content-images></inventory-content-images>',
                    data: { title: gettextCatalog.getString('Monsido - Images') },
                },
            },
            {
                state: 'base.customer.domain.inventory.content.inlineimages',
                config: {
                    url: '/inline_images',
                    template:
                        '<inventory-content-links active="inline_image" type="inline_image" icon="fas fa-file-image" headline="{{\'Inline Images\' | translate}}" export-type="inventory_images"></inventory-content-links>',
                    data: { title: gettextCatalog.getString('Monsido - Inline Images') },
                },
            },
            {
                state: 'base.customer.domain.inventory.content.css',
                config: {
                    url: '/css',
                    template:
                        '<inventory-content-technical active="css" type="css" icon="fab fa-css3" headline="{{ \'CSS\' | translate }}" export-type="inventory_css"></inventory-content-technical>',
                    data: { title: gettextCatalog.getString('Monsido - CSS') },
                },
            },
            {
                state: 'base.customer.domain.inventory.content.js',
                config: {
                    url: '/js',
                    template:
                        '<inventory-content-technical active="js" type="js" icon="fab fa-js-square" headline="{{ \'JavaScript\' | translate }}" export-type="inventory_js"></inventory-content-technical>',
                    data: { title: gettextCatalog.getString('Monsido - JavaScript') },
                },
            },
            {
                state: 'base.customer.domain.inventory.content.form',
                config: {
                    url: '/form',
                    template:
                        '<inventory-content-technical active="form" type="form" icon="{{[\'SIDEMENUES\', \'INVENTORY\', \'TECHNICAL_FORM\'] | monIcons}}" headline="{{\'Forms\' | translate}}" export-type="inventory_form"></inventory-content-technical>',
                    data: { title: gettextCatalog.getString('Monsido - Forms') },
                },
            },
            {
                state: 'base.customer.domain.inventory.content.headlink',
                config: {
                    url: '/headlink',
                    template:
                        '<inventory-content-technical active="headlinks" type="headlink" icon="fas fa-link" headline="{{\'Headlinks\' | translate}}" export-type="inventory_headlink"></inventory-content-technical>',
                    data: { title: gettextCatalog.getString('Monsido - Headlinks') },
                },
            },
            {
                state: 'base.customer.domain.inventory.content.iframe',
                config: {
                    url: '/iframe',
                    template:
                        '<inventory-content-technical active="iframe" type="iframe" icon="fas fa-code" headline="{{\'IFrames\' | translate}}" export-type="inventory_iframe,inventory_iframe_urls"></inventory-content-technical>',
                    data: { title: gettextCatalog.getString('Monsido - IFrame') },
                },
            },
            {
                state: 'base.customer.domain.inventory.content.frame',
                config: {
                    url: '/frame',
                    template:
                        '<inventory-content-technical active="frame" type="frame" icon="fas fa-code" headline="{{\'Frames\' | translate}}" export-type="inventory_frame"></inventory-content-technical>',
                    data: { title: gettextCatalog.getString('Monsido - Frame') },
                },
            },
        ];
    }
})();
