(function () {
    'use strict';

    angular.module('pages.admin.customer_info').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.admin.customer_info',
                config: {
                    url: '/customer',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {},
                },
            },
            {
                state: 'base.customer.admin.customer_info.index',
                config: {
                    url: '/info',
                    template: '<admin-customer-info></admin-customer-info>',
                    data: { title: gettextCatalog.getString('Monsido - Company Information') },
                },
            },
        ];
    }
})();
