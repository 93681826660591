(function () {
    'use strict';

    angular.module('pages.global.policy').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.global.policy',
                config: {
                    url: '/policies',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {
                        topmenu: ['policy'],
                        title: gettextCatalog.getString('Monsido - Summary'),
                    },
                    resolve: {
                        activeFeature: activeFeature,
                    },
                },
            },
            {
                state: 'base.customer.global.policy.dashboard',
                config: {
                    url: '/summary',
                    template: '<global-policy-dashboard></global-policy-dashboard>',
                    data: {
                        title: gettextCatalog.getString('Monsido - Global Policies'),
                    },
                },
            },
            {
                state: 'base.customer.global.policy.policies',
                config: {
                    url: '/list?search',
                    template: '<global-policy-policies></global-policy-policies>',
                    data: {
                        title: gettextCatalog.getString('Monsido - Policy List'),
                    },
                },
            },
        ];
    }

    var activeFeature = [
        '$q',
        '$filter',
        '$state',
        'ng2ActiveFeatureService',
        'ng2MonUIRouterTransitionService',
        function ($q, $filter, $state, ng2ActiveFeatureService, ng2MonUIRouterTransitionService) {
            var validate = $filter('activeFeature')('global_policy');
            if (!ng2ActiveFeatureService.isFeatureActivePure('policies_all') || !validate) {
                ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.dashboard'),
                );
            }
            return $q.resolve(true);
        },
    ];
})();
