(function () {
    'use strict';

    angular.module('pages.admin.source-code-excludes').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.admin.source_code_excludes',
                config: {
                    url: '/accessibility/source-code-excludes',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: { title: gettextCatalog.getString('Monsido - Source Code Excludes') },
                    resolve: {
                        activeFeature: activeFeature,
                    },
                },
            },
            {
                state: 'base.customer.admin.source_code_excludes.index',
                config: {
                    url: '?page_size&page',
                    template: '<admin-source-code-excludes></admin-source-code-excludes>',
                    data: {},
                },
            },
        ];
    }

    var activeFeature = [
        '$q',
        'ng2ActiveFeatureService',
        '$state',
        'ng2MonUIRouterTransitionService',
        function ($q, ng2ActiveFeatureService, $state, ng2MonUIRouterTransitionService) {
            if (
                ng2ActiveFeatureService.isFeatureActive('admin_source_code_excludes') ||
                ng2ActiveFeatureService.isFeatureActive('admin_accessibility')
            ) {
                return $q.resolve(true);
            }

            ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.dashboard'),
            );
        },
    ];
})();
