(function () {
    'use strict';

    angular.module('pages.qa.spellcheck').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    var hasQASummary = [
        'ng2ActiveFeatureService',
        '$q',
        '$state',
        '$stateParams',
        function (ng2ActiveFeatureService, $q, $state, $stateParams) {
            if (!ng2ActiveFeatureService.isFeatureActive('qa_summary')) {
                return $q.resolve(true);
            }
            // TODO: using UITransitionService does not work in this case
            $state.go('base.customer.domain.qa.summary', $stateParams);
        },
    ];

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.domain.qa.spellcheck',
                config: {
                    url: '/spellcheck',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {
                        sidebar: 'quality',
                    },
                    resolve: {
                        hasQASummary,
                    },
                },
            },
            {
                state: 'base.customer.domain.qa.spellcheck.dashboard',
                config: {
                    url: '/dashboard/',
                    template: '<spelling-dashboard></spelling-dashboard>',
                    data: { title: gettextCatalog.getString('Monsido - Spellcheck Summary') },
                },
            },
            {
                state: 'base.customer.domain.qa.spellcheck.pages',
                config: {
                    url: '/pages',
                    template: '<spelling-pages-with-error></spelling-pages-with-error>',
                    data: { title: gettextCatalog.getString('Monsido - Pages With Misspellings') },
                },
            },
            {
                state: 'base.customer.domain.qa.spellcheck.confirmed',
                config: {
                    url: '/confirmed',
                    template: '<spelling-errors-index page-detail-target="misspellings" type="confirmed"></spelling-errors-index>',
                    data: { title: gettextCatalog.getString('Monsido - Misspellings') },
                },
            },
            {
                state: 'base.customer.domain.qa.spellcheck.potential',
                config: {
                    url: '/potential',
                    template:
                        '<spelling-errors-index page-detail-target="potential" type="potential" headline="Potential Misspellings"></spelling-errors-index>',
                    data: { title: gettextCatalog.getString('Monsido - Potential Misspellings') },
                },
            },
            {
                state: 'base.customer.domain.qa.spellcheck.dictionary',
                config: {
                    url: '/dictionary',
                    template: '<spelling-dictionary-index></spelling-dictionary-index>',
                    data: { title: gettextCatalog.getString('Monsido - Account Dictionary') },
                },
            },
            {
                state: 'base.customer.domain.qa.spellcheck.ignored_misspellings',
                config: {
                    url: '/ignored-misspellings',
                    template: '<ignored-misspellings></ignored-misspellings>',
                    data: { title: gettextCatalog.getString('Monsido - Ignored Misspellings') },
                },
            },
            {
                state: 'base.customer.domain.qa.spellcheck.qa_errors',
                config: {
                    url: '/errors',
                    template: '<spelling-qa-errors></spelling-qa-errors>',
                    data: {},
                },
            },
        ];
    }

})();
