(function () {
    'use strict';

    angular.module('services.api').factory('PagePageLinksRepo', PagePageLinksRepo);

    PagePageLinksRepo.$inject = ['ng2ApiService', 'ng2SessionService', 'PageLinkModel'];
    /* @ngInject */
    function PagePageLinksRepo (ng2ApiService, ng2SessionService, PageLinkModel) {
        var service = {
            getAll: getAll,
            get: get,
            update: update,
        };

        return service;

        // /////////////

        function getAll (page_id, params, config) {
            config = config || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/page-links', params, config).then(
                toModels,
            );
        }

        function get (page_id, page_link_id, params, config) {
            config = config || {};
            params.group_id = ng2SessionService.getGroupId(params);
            return ng2ApiService.get(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/page-links/' + page_link_id,
                params,
                config,
            ).then(toModel);
        }

        function update (page_id, page_link, data, params) {
            data = data || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }
            return ng2ApiService.patch(
                'domains/' + ng2SessionService.getDomainId(params) + '/pages/' + page_id + '/page-links/' + page_link,
                data,
                params,
            ).then(toModel);
        }

        // PROTECTED

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }
            return models;
        }

        function toModel (model) {
            return new PageLinkModel(model);
        }
    }
})();
