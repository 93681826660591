(function () {
    'use strict';

    angular.module('pages.global.changesHistory').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.global.changesHistory',
                config: {
                    url: '/change-history?from&to&domain_id&module&group_id',
                    template: '<changes-history></changes-history>',
                    data: { title: gettextCatalog.getString('Monsido - History Center') },
                    resolve: {
                        hasAccess: hasAccess,
                    },
                },
            },
        ];
    }

    var hasAccess = [
        'ng2ActiveFeatureService',
        '$q',
        '$state',
        '$stateParams',
        function (ng2ActiveFeatureService, $q, $state, $stateParams) {
            if (ng2ActiveFeatureService.isFeatureActivePure('history_center_all')) {
                return $q.resolve(true);
            }
            return $state.go('base.customer.dashboard', $stateParams);
        },
    ];
})();
