(function () {
    'use strict';

    angular.module('pages.seo').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.domain.seo',
                config: {
                    url: '/seo',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {
                        sidebar: 'seo',
                    },
                    resolve: {
                        hasAccess: hasAccess,
                    },
                },
            },
            {
                state: 'base.customer.domain.seo.summary',
                config: {
                    url: '/',
                    template: '<seo-summary></seo-summary>',
                    data: {
                        title: gettextCatalog.getString('Monsido - Search Engine Optimization'),
                    },
                },
            },
            {
                state: 'base.customer.domain.seo.issues',
                config: {
                    url: '/checkpoints',
                    template: '<seo-dashboard-issues></seo-dashboard-issues>',
                    data: { title: gettextCatalog.getString('Monsido - SEO Checkpoints') },
                },
            },
            {
                state: 'base.customer.domain.seo.pages',
                config: {
                    url: '/pages-with-opportunities',
                    template: '<seo-pages></seo-pages>',
                    data: { title: gettextCatalog.getString('Monsido - Pages With Opportunity') },
                },
            },
        ];
    }

    var hasAccess = [
        'ng2ActiveFeatureService',
        '$q',
        '$state',
        '$stateParams',
        'ng2MonUIRouterTransitionService',
        function (ng2ActiveFeatureService, $q, $state, $stateParams, ng2MonUIRouterTransitionService) {
            if (ng2ActiveFeatureService.isFeatureActivePure('seo_all')) {
                return $q.resolve(true);
            }
            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.domain.dashboard', $stateParams),
            );
        },
    ];
})();
