import { BrokenLinksUtil } from '@client/app/pages/qa/broken-links/broken-links.util';

(function () {
    'use strict';
    angular.module('pages.qa.broken').component('brokenLinksIndex', {
        templateUrl: 'app/pages/qa/broken-links/links/index.html',
        controller: BrokenLinksIndex,
        controllerAs: 'vm',
        bindings: {
            type: '@',
            filter: '@',
        },
    });
    BrokenLinksIndex.$inject = [
        'LinksRepo',
        '$controller',
        'gettextCatalog',
        'QABrokenLinkService',
        'ng2LabelRepo',
        'ng2MonEventsService',
        'MON_EVENTS',
    ];
    /* @ngInject */
    function BrokenLinksIndex (
        LinksRepo,
        $controller,
        gettextCatalog,
        QABrokenLinkService,
        ng2LabelRepo,
        ng2MonEventsService,
        MON_EVENTS,
    ) {
        var vm = this;
        vm.getPage = getPage;
        vm.$onInit = activate;
        vm.updateFilters = updateFilters;
        vm.$onDestroy = onDestroy;
        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.selected = [];
            vm.search = null;
            vm.type = vm.type || 'not_image';
            vm.links = [];
            vm.tabs = [
                { name: gettextCatalog.getString('All broken links'), faIcon: 'fas fa-unlink', value: 'dead' },
                { name: gettextCatalog.getString('Ignored'), faIcon: 'fas fa-eye-slash', value: 'ignored' },
                { name: gettextCatalog.getString('Marked as fixed'), faIcon: 'fas fa-check-circle', value: 'fixed' },
            ];
            vm.activeTab = 'dead';
            vm.isFiltersOpen = false;
            vm.filters = { labels: [], type: [], 'response code': [], 'internal or external': [] };
            vm.availableFilters = {
                labels: [],
                type: [
                    { id: 'html', name: gettextCatalog.getString('Pages') },
                    { id: 'document', name: gettextCatalog.getString('Documents') },
                ],
                'response code': QABrokenLinkService.getResponseCodes(),
                'internal or external': [
                    { id: 'internal', name: gettextCatalog.getString('Internal') },
                    { id: 'external', name: gettextCatalog.getString('External') },
                ],
            };
            vm.currentSort = { by: 'dead_on_pages_count', direction: 'desc' };
            vm.exports = [
                {
                    name: gettextCatalog.getString('Broken links with page count'),
                    data: {
                        category: 'dead_links',
                    },
                },
                {
                    tooltip: gettextCatalog.getString(
                        'Export list of broken links organized by page and document title',
                    ),
                    name: gettextCatalog.getString('Broken links per page'),
                    data: {
                        category: 'dead_page_links',
                    },
                },
            ];

            vm.qaBrokenLinksUpdateListener = ng2MonEventsService.addListener(MON_EVENTS.RELOAD_QA_BROKEN_LINKS, function () {
                vm.getPage();
            });

            getLabels();
            getPage();
        }

        function getPage () {
            vm.selected = [];
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search,
                status: vm.activeTab,
                type: vm.type,
                sort_by: vm.currentSort.by,
                sort_dir: vm.currentSort.direction,
                page_fixed: false,
            };
            if (vm.selectedLabels && vm.selectedLabels.length > 0) {
                params['labels[]'] = vm.selectedLabels;
            }
            if (vm.filters.type.length) {
                params.page_type = vm.filters.type[0].id;
            }
            if (vm.filters['response code'].length) {
                params.status_code = vm.filters['response code'][0].id;
            }
            if (vm.filters['internal or external'].length) {
                params.relation = vm.filters['internal or external'][0].id;
            }

            vm.promise = LinksRepo.getAll(params).then(function (data) {
                vm.links = data;
            }, angular.noop);
        }
        function updateFilters (changes) {
            if (!changes) {
                return;
            }

            vm.selectedLabels = [];
            if (changes.labels.length > 0) {
                for (const label of changes.labels) {
                    vm.selectedLabels.push(label.id);
                }
            }

            vm.filters = BrokenLinksUtil.updateLinksFilters(vm.filters, changes);
            vm.getPage();
        }

        function getLabels () {
            ng2LabelRepo.getLabels().then(function (data) {
                vm.labels = data || [];
                vm.availableFilters.labels = data || [];
            }, angular.noop);
        }

        function onDestroy () {
            ng2MonEventsService.remove(vm.qaBrokenLinksUpdateListener);
        }
    }
})();
