(function () {
    'use strict';

    angular.module('pages.qa').component('qualitySummary', {
        templateUrl: 'app/pages/qa/summary/summary.html',
        controller: QaSummaryController,
        controllerAs: 'vm',
    });

    QaSummaryController.$inject = [
        '$stateParams',
        'ng2QualityRepoService',
        'ng2CrawlsRepoService',
        'ng2SpellingRepoService',
        'LinksRepo',
        'ng2ReadabilityRepoService',
        '$filter',
        '$q',
        'gettextCatalog',
        'ng2ActiveFeatureService',
    ];

    function QaSummaryController (
        $stateParams,
        ng2QualityRepoService,
        ng2CrawlsRepoService,
        ng2SpellingRepoService,
        LinksRepo,
        ng2ReadabilityRepoService,
        $filter,
        $q,
        gettextCatalog,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.$onInit = activate;

        function activate () {
            vm.isOnlyQASummary = ng2ActiveFeatureService.isFeatureActive('qa_summary');
            vm.domainId = $stateParams.domainId;
            vm.hasReadability = ng2ActiveFeatureService.isFeatureActivePure('admin_readability');
            vm.readabilitySummary = {};
            vm.qaSummary = {};
            vm.history = [];
            vm.spellingErrors = [];
            vm.brokenLink = {};
            vm.brokenImage = {};
            vm.loading = true;

            var promises = [getQASummary(), getCrawls(), getSpellingErrors(), getLinks('not_image', 'dead'), getLinks('image', 'dead')];

            if (vm.hasReadability) {
                promises.push(getReadabilitySummary());
            }

            vm.exports = [
                {
                    name: gettextCatalog.getString('Quality Assurance Export summary'),
                    data: {
                        category: 'qa_summary',
                    },
                },
            ];

            vm.progress = $q.all(promises).then(function (values) {
                vm.qaSummary = values[0];
                vm.history = values[1].reverse();
                vm.spellingErrors = values[2];
                vm.brokenLink = values[3].length > 0 ? values[3][0] : null;
                vm.brokenImage = values[4].length > 0 ? values[4][0] : null;

                if (vm.hasReadability) {
                    vm.readabilitySummary = values[5];
                }
                vm.loading = false;
            }, angular.noop);
        }

        function getQASummary () {
            var params = {
                domainId: vm.domainId,
            };
            return ng2QualityRepoService.getSummary(params);
        }

        function getReadabilitySummary () {
            return ng2ReadabilityRepoService.summary();
        }

        function getCrawls () {
            var params = {
                page: 1,
                page_size: 5,
            };
            return ng2CrawlsRepoService.getAll(params);
        }

        function getSpellingErrors () {
            var params = {
                domainId: vm.domainId,
                page: 1,
                page_size: 1,
                sort_by: 'html_pages_count',
                classification: 'confirmed',
                page_fixed: false,
            };
            return ng2SpellingRepoService.getAllErrors(params);
        }

        function getLinks (type, status, sortBy) {
            var params = {
                page: 1,
                page_size: 1,
                status: status,
                type: type,
                sort_by: sortBy || 'dead_on_pages_count',
                sort_dir: 'desc',
                page_fixed: false,
            };
            return LinksRepo.getAll(params);
        }
    }
})();
