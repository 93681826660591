(function () {
    'use strict';

    angular.module('pages.dashboard').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.dashboard',
                config: {
                    url: '/?page&page_size&search&sort_by&sort_direction',
                    template: '<dashboard></dashboard>',
                    data: { anonymous_access: false, title: gettextCatalog.getString('Monsido - Dashboard') },
                    resolve: {
                        hasAccessToDomain: hasAccessToDomain,
                    },
                },
            },
        ];
    }

    var hasAccessToDomain = [
        'ng2SessionService',
        '$state',
        '$q',
        '$stateParams',
        'ng2MonUIRouterTransitionService',
        function (ng2SessionService, $state, $q, $stateParams, ng2MonUIRouterTransitionService) {
            if (ng2SessionService.customer) {
                if (ng2SessionService.isLiteCustomer()) {
                    return ng2MonUIRouterTransitionService.onTransitionFinished(
                        () => $state.go('base.customer.light.domains', $stateParams),
                    );
                }
            } else {
                return ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.dashboard', $stateParams),
                );
            }

            return $q.resolve(true);
        },
    ];
})();
