(function () {
    'use strict';

    angular.module('modules.data_privacy.services.api').service('DataPrivacyContentRepo', DataPrivacyContentRepo);

    DataPrivacyContentRepo.$inject = [
        'ng2ApiService',
        'ng2SessionService',
        'ng2PrivacyFastTrackModelFactoryService',
    ];
    /* @ngInject */
    function DataPrivacyContentRepo (ng2ApiService, ng2SessionService, ng2PrivacyFastTrackModelFactoryService) {
        return {
            getAll: getAll,
        };

        // /////////////

        function getAll (params, config) {
            config = config || {};
            params = params || {};
            if (!params.group_id) {
                params.group_id = ng2SessionService.getGroupId(params);
            }

            return ng2ApiService.get('domains/' + ng2SessionService.getDomainId(params) + '/data_protection/contents', params, config).then(toModels);
        }

        // PROTECTED

        function toModels (models) {
            for (var i = 0; i < models.length; i++) {
                models[i] = toModel(models[i]);
            }

            return models;
        }

        function toModel (model) {
            return ng2PrivacyFastTrackModelFactoryService.getModel(model);
        }
    }
})();
