(function () {
    'use strict';

    angular.module('pages.uptimes').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.domain.uptimes',
                config: {
                    url: '/uptimes',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: { title: gettextCatalog.getString('Monsido - Heartbeat') },
                    resolve: {
                        validateAccess: validateAccess,
                        validateDomain: validateDomain,
                    },
                },
            },
            {
                state: 'base.customer.domain.uptimes.index',
                config: {
                    url: '?page&sort_by&sort_dir&&search&page_size&status',
                    template: '<uptimes-overview></uptimes-overview>',
                    data: {},
                    resolve: {
                        uptimesResult: redirectUptimes,
                    },
                },
            },
            {
                state: 'base.customer.domain.uptimes.show',
                config: {
                    url: '/{uptimeId:\\d+}',
                    template: '<uptime-show checkpoints=\'vm.checkpointCount\'></uptime-show>',
                    controllerAs: 'vm',
                    controller: [
                        'checkpointCount',
                        function (checkpointCount) {
                            this.checkpointCount = checkpointCount;
                        },
                    ],
                    data: {
                        isScopedToDomain: true,
                        redirectToOnDomainChange: 'base.customer.domain.uptimes.index',
                    },
                    resolve: {
                        checkpointCount: checkpointCount,
                    },
                },
            },
        ];
    }

    var checkpointCount = [
        'ng2UptimeRepoService',
        '$stateParams',
        function (ng2UptimeRepoService, $stateParams) {
            return ng2UptimeRepoService.getAll({}, $stateParams.domainId).then(function (uptimes) {
                return uptimes.length;
            }, angular.noop);
        },
    ];

    var redirectUptimes = [
        '$q',
        '$state',
        '$stateParams',
        '$timeout',
        'ng2UptimeRepoService',
        'ng2MonUIRouterTransitionService',
        function ($q, $state, $stateParams, $timeout, ng2UptimeRepoService, ng2MonUIRouterTransitionService) {
            var deferred = $q.defer();
            ng2UptimeRepoService.getAll({}, $stateParams.domainId).then(
                function (data) {
                    if (data.length === 1) {
                        $timeout(function () {
                            ng2MonUIRouterTransitionService.onTransitionFinished(
                                () => $state.go('base.customer.domain.uptimes.show', {
                                    customerId: $stateParams.customerId,
                                    domainId: $stateParams.domainId,
                                    uptimeId: data[0].id,
                                }),
                            );
                        });
                        deferred.reject({ redirectComing: true });
                    } else {
                        deferred.resolve(data);
                    }
                },
                function (data) {
                    ng2MonUIRouterTransitionService.onTransitionFinished(
                        () => $state.go('base.dashboard'),
                    );
                    deferred.reject();
                },
            );
            return deferred.promise;
        },
    ];

    var validateAccess = [
        '$q',
        'ng2SessionService',
        '$state',
        '$stateParams',
        'ng2MonUIRouterTransitionService',
        function ($q, ng2SessionService, $state, $stateParams, ng2MonUIRouterTransitionService) {
            // Check if plan allows access to uptimes
            var deferred = $q.defer();
            if (ng2SessionService.customer !== null && ng2SessionService.customer.plan_traits.heartbeat) {
                deferred.resolve(true);
            } else {
                ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.customer.domain.dashboard', $stateParams),
                );
            }
            return deferred.promise;
        },
    ];

    var validateDomain = [
        '$q',
        'ng2SessionService',
        '$state',
        'ng2MonEventsService',
        '$stateParams',
        'ng2MonUIRouterTransitionService',
        function ($q, ng2SessionService, $state, ng2MonEventsService, $stateParams, ng2MonUIRouterTransitionService) {
            // Validate if domain group is set
            var deferred = $q.defer();
            if (ng2SessionService.domainGroup !== null) {
                ng2MonEventsService.run('changed-domain');
                ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.customer.domain.dashboard', $stateParams),
                );
            } else {
                deferred.resolve(true);
            }
            return deferred.promise;
        },
    ];
})();
