import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.uptimes').component('uptimesOverview', {
        templateUrl: 'app/pages/uptimes/uptimes.html',
        controller: UptimesOverviewController,
        controllerAs: 'vm',
    });

    UptimesOverviewController.$inject = [
        'ng2UptimeRepoService',
        'ng2MonEventsService',
        '$controller',
        '$location',
        'ParamService',
        '$state',
        'ng2MonModalService',
        'uptimeService',
        'ng2MonUIRouterTransitionService',
        'gettextCatalog',
        'ng2MonPromptService',
    ];
    /* @ngInject */
    function UptimesOverviewController (
        ng2UptimeRepoService,
        ng2MonEventsService,
        $controller,
        $location,
        ParamService,
        $state,
        ng2MonModalService,
        uptimeService,
        ng2MonUIRouterTransitionService,
        gettextCatalog,
        ng2MonPromptService,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.getPage = getPage;
        vm.openUptime = openUptime;
        vm.uptimeShow = uptimeShow;
        vm.uptimeDelete = uptimeDelete;
        vm.onAddNew = onAddNew;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.loading = true;
            vm.uptimes = [];
            vm.date = {
                startDate: '',
                endDate: '',
            };
            vm.MAX_CHECKS_RECOMMENDED = 10;

            loadLocations();
            getPage();
        }

        function loadLocations () {
            vm.search = $location.search().search || 'all';
            vm.sortBy = $location.search().sort_by || '';
            vm.sortDirection = $location.search().sort_dir || '';
            vm.activeTab = $location.search().status || 'active';
            vm.date.startDate = vm.date.startDate || moment().startOf('day')
                .subtract(7, 'days');
            vm.date.endDate = vm.date.endDate || moment().subtract(1, 'days')
                .endOf('day');
        }

        function getPage () {
            ParamService.setParams({
                page: vm.page,
                page_size: vm.pageSize,
                sort_by: vm.sortBy || null,
                sort_dir: vm.sortDirection || null,
                search: vm.search || null,
                status: vm.activeTab || null,
                start_date: vm.date.startDate,
                end_date: vm.date.endDate,
            });
            getPages();
        }

        function getPages () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                status: vm.activeTab,
                search: vm.activeTab === 'filtered' ? vm.search : null,
                sort_by: vm.sortBy,
                sort_direction: vm.sortDirection,
                start_date: vm.date.startDate.format('YYYY-MM-DD'),
                end_date: vm.date.endDate.format('YYYY-MM-DD'),
            };
            vm.promise = ng2UptimeRepoService.getAll(params)
                .then(function (data) {
                    vm.uptimes = data;
                    updateDropdowns();
                }, angular.noop)
                .finally(function () {
                    if (vm.uptimes.length === 1) {
                        uptimeShow(vm.uptimes[0]);
                    } else {
                        vm.loading = false;
                    }
                });
        }

        function updateDropdowns () {
            for (const uptime of vm.uptimes) {
                const dropdown = [
                    {
                        label: gettextCatalog.getString('Edit'),
                        leftIcon: 'faCog',
                        action: ()=>openUptime(uptime),
                        eventParams: { action: 'heartbeat-edit' },
                    },
                    {
                        label: gettextCatalog.getString('Remove checkpoint'),
                        leftIcon: 'faTrashAlt',
                        action: ()=>uptimeDelete(uptime),
                        eventParams: { action: 'heartbeat-remove-check' },
                    },
                ];

                uptime.dropdown = dropdown.map(item => {
                    return {
                        label: item.label,
                        leftIcon: item.leftIcon,
                        action: ()=>{
                            ng2MonEventsService.run('actionTrigger', item.eventParams);
                            item.action();
                        },
                    };
                });
            }
        }

        function onAddNew () {
            if (vm.uptimes.length >= vm.MAX_CHECKS_RECOMMENDED) {
                showPrompt();
            } else {
                openUptime();
            }

        }

        function openUptime (uptime) {
            var params = {
                body: 'uptime',
                size: 'md',
                data: {
                    uptime: uptime,
                },
            };
            const callback = function (uptime) {
                if (angular.isObject(uptime)) {
                    getPage();
                }
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback });
        }

        function showPrompt () {
            ng2MonPromptService.custom({
                // eslint-disable-next-line max-len
                message: gettextCatalog.getString('Every page added is checked by Heartbeat several times every hour. Each check involves Monsido making a request to your website server. Be aware that adding additional pages will increase the total number of requests which may cause issues with server load on your website or increase your hosting costs with your website hosting provider.'),
                title: gettextCatalog.getString('Website performance and hosting cost warning'),
                buttons: [
                    {
                        label: gettextCatalog.getString('Cancel'),
                        className: 'btn btn-default',
                        callback: () => {},
                    },
                    {
                        label: gettextCatalog.getString('Proceed to add one more check'),
                        className: 'btn btn-primary',
                        callback: () => openUptime(),
                    },
                ],
            })
                .catch(() => {});
        }

        function uptimeDelete (uptime) {
            var msg = 'Are you sure you want to delete the checkpoint for <strong>' + uptime.url + '</strong>?';
            ng2MonModalService.confirm(msg).then(function () {
                uptimeService.destroy(uptime.id).then(function () {
                    getPage();
                }, angular.noop);
            }, angular.noop);
        }

        function uptimeShow (uptime) {
            var to = 'base.customer.domain.uptimes.show';
            ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go(to, { uptimeId: uptime.id }),
            );
        }
    }
})();
