(function () {
    'use strict';

    angular.module('pages.statistics').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.domain.statistics',
                config: {
                    url: '/statistics',
                    abstract: true,
                    template: '<statistics-cap-text ng-if=\'vm.showTooltip\'></statistics-cap-text><div class=\'statistics\' ui-view></div>',
                    controllerAs: 'vm',
                    controller: [
                        'showTooltip',
                        function (showTooltip) {
                            this.showTooltip = showTooltip;
                        },
                    ],
                    data: {
                        sidebar: 'statistics',
                        title: gettextCatalog.getString('Monsido - Statistics'),
                    },
                    resolve: {
                        validateDomain: validateFeature,
                        showTooltip: showTooltip,
                    },
                },
            },
            {
                state: 'base.customer.domain.statistics.summary',
                config: {
                    url: '/',
                    template: '<statistics-summary></statistics-summary>',
                    data: {},
                },
            },
            {
                state: 'base.customer.domain.statistics.visitors',
                config: {
                    url: '/visitors',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {},
                },
            },
            {
                state: 'base.customer.domain.statistics.visitors.summary',
                config: {
                    url: '/summary',
                    template: '<statistics-visitors-summary></statistics-visitors-summary>',
                    data: { title: gettextCatalog.getString('Monsido - Visitors Summary') },
                },
            },
            {
                state: 'base.customer.domain.statistics.visitors.locations',
                config: {
                    url: '/locations',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: { title: gettextCatalog.getString('Monsido - Locations') },
                },
            },
            {
                state: 'base.customer.domain.statistics.visitors.locations.index',
                config: {
                    url: '/',
                    template: '<statistics-visitors-locations></statistics-visitors-locations>',
                    data: {},
                },
            },
            {
                state: 'base.customer.domain.statistics.visitors.locations.regions',
                config: {
                    url: '/{countryCode}?countryName',
                    template: '<statistics-visitors-regions></statistics-visitors-regions>',
                    data: {},
                    params: {
                        countryName: '',
                    },
                },
            },
            {
                state: 'base.customer.domain.statistics.visitors.locations.cities',
                config: {
                    url: '/{countryCode}/{regionCode}?countryName&regionName',
                    template: '<statistics-visitors-cities></statistics-visitors-cities>',
                    data: {},
                    params: {
                        regionName: '',
                        countryName: '',
                    },
                },
            },
            {
                state: 'base.customer.domain.statistics.visitors.language',
                config: {
                    url: '/language',
                    template: '<statistics-visitors-language></statistics-visitors-language>',
                    data: { title: gettextCatalog.getString('Monsido - Languages') },
                },
            },
            {
                state: 'base.customer.domain.statistics.visitors.organizations',
                config: {
                    url: '/organizations',
                    template: '<statistics-visitors-organizations></statistics-visitors-organizations>',
                    data: { title: gettextCatalog.getString('Monsido - Organizations') },
                },
            },
            {
                state: 'base.customer.domain.statistics.visitors.devices',
                config: {
                    url: '/devices',
                    template: '<statistics-visitors-devices></statistics-visitors-devices>',
                    data: { title: gettextCatalog.getString('Monsido - Devices') },
                },
            },
            {
                state: 'base.customer.domain.statistics.visitors.browsers',
                config: {
                    url: '/browsers',
                    template: '<statistics-visitors-browsers></statistics-visitors-browsers>',
                    data: { title: gettextCatalog.getString('Monsido - Browsers') },
                },
            },
            {
                state: 'base.customer.domain.statistics.visitors.os',
                config: {
                    url: '/os',
                    template: '<statistics-visitors-os></statistics-visitors-os>',
                    data: { title: gettextCatalog.getString('Monsido - Operating Systems') },
                },
            },
            {
                state: 'base.customer.domain.statistics.visitors.resolutions',
                config: {
                    url: '/resolutions',
                    template: '<statistics-visitors-resolutions></statistics-visitors-resolutions>',
                    data: { title: gettextCatalog.getString('Monsido - Screen Resolutions') },
                },
            },
            {
                state: 'base.customer.domain.statistics.visitors.log',
                config: {
                    url: '/log',
                    template: '<statistics-visitors-log></statistics-visitors-log>',
                    data: {},
                },
            },
            {
                state: 'base.customer.domain.statistics.pages',
                config: {
                    url: '/pages',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {},
                },
            },
            {
                state: 'base.customer.domain.statistics.sources',
                config: {
                    url: '/sources',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {},
                },
            },
            {
                state: 'base.customer.domain.statistics.pages.mostPopular',
                config: {
                    url: '/most-popular',
                    template: '<statistics-most-popular-pages></statistics-most-popular-pages>',
                    data: { title: gettextCatalog.getString('Monsido - Most Popular Pages') },
                },
            },
            {
                state: 'base.customer.domain.statistics.pages.leastPopular',
                config: {
                    url: '/least-popular',
                    template: '<statistics-least-popular-pages></statistics-least-popular-pages>',
                    data: { title: gettextCatalog.getString('Monsido - Least Popular Pages') },
                },
            },
            {
                state: 'base.customer.domain.statistics.pages.mostDownloads',
                config: {
                    url: '/most-downloads',
                    template: '<statistics-most-downloads></statistics-most-downloads>',
                    data: { title: gettextCatalog.getString('Monsido - Most Downloads') },
                },
            },
            {
                state: 'base.customer.domain.statistics.pages.withoutVisits',
                config: {
                    url: '/without-visits',
                    template: '<statistics-pages-without-visits></statistics-pages-without-visits>',
                    data: { title: gettextCatalog.getString('Monsido - Pages without Visits') },
                },
            },
            {
                state: 'base.customer.domain.statistics.pages.documentsWithoutDownloads',
                config: {
                    url: '/documents-without-visits',
                    template: '<statistics-pages-documents-without-downloads></statistics-pages-documents-without-downloads>',
                    data: { title: gettextCatalog.getString('Monsido - Documents Without Downloads') },
                },
            },
            {
                state: 'base.customer.domain.statistics.sources.summary',
                config: {
                    url: '/summary',
                    template: '<statistics-sources-summary></statistics-sources-summary>',
                    data: { title: gettextCatalog.getString('Monsido - Traffic Sources Summary') },
                },
            },
            {
                state: 'base.customer.domain.statistics.sources.searchEngines',
                config: {
                    url: '/search-engines',
                    template: '<statistics-sources-search-engines></statistics-sources-search-engines>',
                    data: { title: gettextCatalog.getString('Monsido - Search Engines') },
                },
            },
            {
                state: 'base.customer.domain.statistics.sources.social',
                config: {
                    url: '/social',
                    template: '<statistics-sources-social></statistics-sources-social>',
                    data: { title: gettextCatalog.getString('Monsido - Social Networks') },
                },
            },
            {
                state: 'base.customer.domain.statistics.sources.domains',
                config: {
                    url: '/domains',
                    template: '<statistics-sources-referrers></statistics-sources-referrers>',
                    data: { title: gettextCatalog.getString('Monsido - External Referring Domains') },
                },
            },
            {
                state: 'base.customer.domain.statistics.campaigns',
                config: {
                    url: '/campaigns',
                    template: '<statistics-campaigns></statistics-campaigns>',
                    data: {},
                },
            },
            {
                state: 'base.customer.domain.statistics.entryExit',
                config: {
                    url: '/entry-exit',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {},
                },
            },
            {
                state: 'base.customer.domain.statistics.entryExit.entry',
                config: {
                    url: '/entry',
                    template: '<statistics-entry-pages></statistics-entry-pages>',
                    data: { title: gettextCatalog.getString('Monsido - Entry Pages') },
                },
            },
            {
                state: 'base.customer.domain.statistics.entryExit.exit',
                config: {
                    url: '/exit',
                    template: '<statistics-exit-pages></statistics-exit-pages>',
                    data: { title: gettextCatalog.getString('Monsido - Exit Pages') },
                },
            },
            {
                state: 'base.customer.domain.statistics.entryExit.bounceRates',
                config: {
                    url: '/bounce-rates',
                    template: '<statistics-bounce-rates-pages></statistics-bounce-rates-pages>',
                    data: { title: gettextCatalog.getString('Monsido - Bounce Rates') },
                },
            },
        ];
    }

    var validateFeature = [
        '$q',
        'ng2SessionService',
        '$state',
        'ng2MonEventsService',
        '$filter',
        '$stateParams',
        'ng2MonUIRouterTransitionService',
        function ($q, ng2SessionService, $state, ng2MonEventsService, $filter, $stateParams, ng2MonUIRouterTransitionService) {
            // Validate if domain group is set
            var statEnabledOnDomain = ng2SessionService.domain !== null && ng2SessionService.domain.features.statistics;
            var statEnabledOnDomainGroup =
                ng2SessionService.domainGroup !== null &&
                ng2SessionService.customer !== null &&
                ng2SessionService.customer.plan_traits.domain_group_statistics;
            var isDemo = $filter('monCustomerHasPlan')('demo');
            var deferred = $q.defer();

            if (isDemo) {
                deferred.resolve(true);
            } else if (statEnabledOnDomain && ng2SessionService.domainGroup === null) {
                deferred.resolve(true);
            } else if (statEnabledOnDomain && statEnabledOnDomainGroup) {
                deferred.resolve(true);
            } else {
                ng2MonEventsService.run('changed-domain');
                ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.customer.domain.dashboard', $stateParams),
                );
                deferred.resolve(false);
            }
            return deferred.promise;
        },
    ];

    var showTooltip = [
        'ng2SessionService',
        '$filter',
        function (ng2SessionService, $filter) {
            if (ng2SessionService.domain !== null) {
                return !ng2SessionService.domain.monsido_script_installed && !$filter('isStatsSampleData')(ng2SessionService.domain);
            }
            return false;
        },
    ];
})();
