import { Domain } from '@monsido/ng2/modules/models/api/domain';
import { domainModelUtils } from '@monsido/modules/models/api/domain-model-utils/domain-model.utils';

(function () {
    'use strict';

    angular.module('app.forms').component('formDomain', {
        templateUrl: 'app/forms/domain/domain.html',
        controller: DomainController,
        controllerAs: 'vm',
        bindings: {
            domain: '<?',
            monDialogId: '@?',
            setForm: '&?',
        },
    });

    DomainController.$inject = [
        'monDialog',
        'UserRepo',
        'ng2DomainRepoService',
        '$q',
        'smartScroll',
        'Lodash',
        '$timeout',
        'ng2DomainService',
        'ng2ActiveFeatureService',
        'ng2CoreDomainService',
        'ng2TotalDomainsService',
        'moment',
    ];

    /* @ngInject */
    function DomainController (
        monDialog,
        UserRepo,
        ng2DomainRepoService,
        $q,
        smartScroll,
        Lodash,
        $timeout,
        ng2DomainService,
        ng2ActiveFeatureService,
        ng2CoreDomainService,
        ng2TotalDomainsService,
        moment,
    ) {
        var vm = this;
        vm.$onInit = activate;
        vm.submit = submit;
        vm.scrollTo = scrollTo;
        vm.getDomain = getDomain;
        vm.showPageAssist = showPageAssist;
        vm.isScanValid = true;

        function activate () {
            var promises = [];
            vm.isContstraintsLoading = false;
            vm.isLinkExcludesLoading = false;
            vm.offset = moment()
                .utcOffset(); // Timezone in minutes
            vm.isSaving = false;
            vm.progress = false;
            vm.users = [];
            vm.loading = true;
            vm.domain = new Domain(vm.domain) || {};
            vm.originalDomain = {};
            vm.pageAssistSettings = {};
            promises.push(getUsers());
            if (typeof vm.domain.id === 'number') {
                promises.push(getDomain());
            }
            vm.progress = $q
                .all(promises)
                .then(function (values) {
                    vm.users = values[0];
                    if (values[1] !== null && typeof values[1] === 'object') {
                        vm.domain = values[1];
                        vm.originalDomain = angular.copy(vm.domain);
                    }
                }, angular.noop)
                .finally(function () {
                    vm.loading = false;
                });

            $timeout(function () {
                if (vm.setForm) {
                    vm.setForm({ id: vm.monDialogId, form: vm.domainForm });
                }
            });
        }

        vm.updateScanAuthValidity = (value) => {
            vm.isScanValid = value;
        };

        function getDomain () {
            return ng2DomainRepoService.get(vm.domain.id);
        }

        function getUsers () {
            return UserRepo.getAll({ page_size: 0 });
        }

        function showPageAssist () {
            return ng2ActiveFeatureService.isFeatureActive('page_assist', vm.domain);
        }

        function submit () {
            var promise;
            var domain = angular.copy(vm.domain);
            vm.isSaving = true;
            const { day, time } = domainModelUtils.getScanDayTimeUTC(domain, vm.offset);
            domain.scan.day = day;
            domain.scan.time = time;
            domain.link_excludes = clearEmptyLinkExcludes(domain.link_excludes);
            domain.path_constraints = clearEmptyPathConstraints(domain.path_constraints);
            domain.additional_internal_urls = clearEmptyInternalUrls(domain.additional_internal_urls);
            domain.language = !domain.language ? null : domain.language;
            domain.domain_users = setDomainUsersParams(domain);
            domain.scan.login = ng2CoreDomainService.setupScanLogin(domain.scan.login);

            if (!domain.features.page_fix) {
                delete domain.settings.scripts.page_correct.admin_only;
                delete domain.settings.scripts.page_correct.enabled_checks;
            }

            if (Array.isArray(domain.labels)) {
                domain.labels = domain.labels.map(function (label) {
                    return label.id;
                });
            }

            if (domain.id) {
                promise = ng2DomainService.update(domain.id, domain);
            } else {
                promise = ng2DomainService.create(domain);
            }

            promise
                .then(function (domainData) {
                    if (vm.domainForm) {
                        vm.domainForm.$setPristine();
                    }
                    if (domainData) {
                        ng2TotalDomainsService.updateDomain(domainData);
                        close();
                    }

                }, angular.noop)
                .finally(function () {
                    vm.isSaving = false;
                });
        }

        function setDomainUsersParams (domain) {
            return domain.domain_users.map(function (domainUser) {
                var index = Lodash.findIndex(vm.users, function (user) {
                    return user.id === domainUser.user_id;
                });

                if (index > -1) {
                    domainUser.visible = vm.users[index].customer_admin || domainUser.visible;
                    domainUser.send_report = domainUser.visible && domainUser.send_report;
                } else {
                    domainUser.visible = false;
                    domainUser.send_report = false;
                }

                return domainUser;
            });
        }

        function clearEmptyInternalUrls (internalUrls) {
            return internalUrls.filter(function (url) {
                return typeof url.value === 'string' && url.value.trim().length > 0;
            });
        }

        function clearEmptyPathConstraints (constraints) {
            return constraints.filter(function (constraint) {
                return constraint.hasOwnProperty('constraint') && constraint.constraint.length > 0;
            });
        }

        function clearEmptyLinkExcludes (excludes) {
            return excludes.filter(function (exclude) {
                return exclude.hasOwnProperty('regex') && exclude.regex.length > 0;
            });
        }

        function close () {
            monDialog.close(vm.monDialogId, vm.domain);
        }

        function scrollTo (sectionID) {
            var container = document.querySelector('.mon-dialog-body-with-footer');
            smartScroll.scrollTo(container, sectionID, 120);
        }
    }

})();
