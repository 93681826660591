(function () {
    'use strict';

    angular.module('modules.light').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.light',
                config: {
                    url: '/lite',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: { title: gettextCatalog.getString('Monsido - Lite') },
                    resolve: {
                        hasAccess: hasAccess,
                    },
                },
            },
        ];
    }
    var hasAccess = [
        'ng2SessionService',
        '$state',
        '$q',
        '$stateParams',
        'ng2MonUIRouterTransitionService',
        function (ng2SessionService, $state, $q, $stateParams, ng2MonUIRouterTransitionService) {
            if (ng2SessionService.customer) {
                if (ng2SessionService.isLiteCustomer()) {
                    return $q.resolve(true);
                }
            } else {
                return ng2MonUIRouterTransitionService.onTransitionFinished(
                    () => $state.go('base.dashboard', $stateParams),
                );
            }

            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.dashboard', $stateParams),
            );
        },
    ];
})();
