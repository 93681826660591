(function () {
    'use strict';

    angular.module('core.run').run([
        '$rootScope',
        'ng2SessionService',
        '$state',
        'devMode',
        '$stateParams',
        '$location',
        '$transitions',
        '$trace',
        'environment',
        'ng2ErrorHandler',
        'ng2OauthService',
        'ng2CoreDomainService',
        'ng2CoreLoginService',
        '$window',
        '$timeout',
        'gettextCatalog',
        '$q',
        'ng2MonUIRouterTransitionService',
        'ng2RollbarErrorHandlerService',
        'ng2MonEventsService',
        '$numeraljsConfig',

        function (
            $rootScope,
            ng2SessionService,
            $state,
            devMode,
            $stateParams,
            $location,
            $transitions,
            $trace,
            environment,
            ng2ErrorHandler,
            oauthService,
            ng2CoreDomainService,
            ng2CoreLoginService,
            $window,
            $timeout,
            gettextCatalog,
            $q,
            ng2MonUIRouterTransitionService,
            ng2RollbarErrorHandlerService,
            ng2MonEventsService,
            $numeraljsConfig,
        ) {

            // The following code should be kept
            // until all usages of numeraljs in the js-files
            // are removed
            ng2MonEventsService.addListener('numeraljsSetLocale', (locale) => {
                $numeraljsConfig.locale(locale);
            });

            $state.defaultErrorHandler(error => {
                // Do nothing if the transition has been superseded by a different transition
                // Error type references in the following file
                // https://github.com/ui-router/core/blob/d461776/src/transition/rejectFactory.ts#L67
                if (error.type === 2) {
                    return;
                }
                // Treat other transition rejections as warning
                ng2RollbarErrorHandlerService.warn(error);
            });

            $transitions.onStart({}, function (transition) {
                if ($state.current.name !== '' && transition.to().name !== $state.current.name) {
                    ng2SessionService.previousParams = angular.copy($stateParams);
                }

                if ($location.search().hasOwnProperty('sudo')) {
                    oauthService.useMemoryStore();
                    ng2SessionService.isSudo = true;
                }

                if (!transition.to().data.anonymous_access) {
                    if (ng2CoreLoginService.isLoggedIn()) {
                        if (!ng2SessionService.isUserLoaded()) {
                            return ng2CoreLoginService.loadUser().then(function () {
                                if ('login.login' === transition.to().name) {
                                    return $state.target('base.dashboard');
                                } else {
                                    ng2CoreDomainService.setupDomainGroupAfterReload();
                                }
                            }, ng2ErrorHandler.standardMsg);
                        }
                    } else {
                        // All the params set from the url has 'undefined' values in transition.params.
                        const { issueOverlayIssueId,
                            issueOverlayIssueType,
                            issueOverlaySourceCodeId,
                            issueOverlayContentId,
                            issueOverlayPageId,
                            issueOverlayCheckId,
                            issueOverlayPageLinkId } = $location.search();

                        // $location.search() does not return overlay value
                        const issueOverlay = location.search.includes('issueOverlay=true') ? 'true' : null;
                        const transParams = Object.assign(
                            Object.assign({}, transition.params('to')),
                            {
                                issueOverlay,
                                issueOverlayIssueId,
                                issueOverlayIssueType,
                                issueOverlaySourceCodeId,
                                issueOverlayContentId,
                                issueOverlayPageId,
                                issueOverlayCheckId,
                                issueOverlayPageLinkId,
                            },
                        );

                        oauthService.setRedirectState(
                            transition.to().name, Object.assign(transParams),
                        );
                        return $state.target('login.login');
                    }
                }

                // Handle scoped to domain endpoints (like fx. /uptimes/:id)
                // These routes should be redirected when the domain is changed
                // if (transition.to().data.isScopedToDomain) {
                //     if (angular.isDefined(transition.params("from").domainId) && angular.isDefined(transition.params("to").domainId)) {
                //         if (transition.params("from").domainId !== transition.params("to").domainId) {
                //             return $state.target(transition.to().data.redirectToOnDomainChange, {
                //                 domainId: transition.params("to").domainId,
                //             });
                //         }
                //     }
                // }

                return true;
            });

            $transitions.onFinish({}, function (transition) {
                var toState = transition.to();
                if (toState.data && toState.data.features && ng2SessionService.domain && ng2SessionService.domain.features) {
                    var hasFeature = false;
                    angular.forEach(toState.data.features, function (value, key) {
                        if (ng2SessionService.domain.features[value]) {
                            hasFeature = true;
                        }
                    });
                    if (!hasFeature) {
                        return ng2MonUIRouterTransitionService.onTransitionFinished(
                            () => $state.go('base.customer.domain.dashboard', transition.params()),
                        );
                    }
                }
                return true;
            });

            $transitions.onSuccess({}, function (transition) {
                $timeout(function () {
                    $window.document.title = transition.to().data.title || gettextCatalog.getString('Monsido');
                }, 0);

                $rootScope.previousState = transition.from().name;
                //  analyticsService.trackPage();
            });

            $transitions.onError({}, function () {
                return $q.resolve();
            });
        },
    ]);
})();
