(function () {
    'use strict';

    angular.module('modules.heatmaps').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.heatmaps',
                config: {
                    url: '/heatmaps',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: { title: gettextCatalog.getString('Monsido - Heatmaps') },
                    resolve: {
                        hasAccess: hasAccess,
                    },
                },
            },
            {
                state: 'base.customer.heatmaps.dashboard',
                config: {
                    url: '',
                    template: '<heatmaps-dashboard></heatmaps-dashboard>',
                    data: {},
                },
            },
        ];
    }
    var hasAccess = [
        'ng2ActiveFeatureService',
        '$state',
        '$q',
        '$stateParams',
        'ng2DemoHasAccessService',
        'ng2MonUIRouterTransitionService',
        function (ng2ActiveFeatureService, $state, $q, $stateParams, ng2DemoHasAccessService, ng2MonUIRouterTransitionService) {
            if (ng2DemoHasAccessService.isDemoHeatmaps() || ng2ActiveFeatureService.isFeatureActive('admin_heatmaps')) {
                return $q.resolve(true);
            }

            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.domain.dashboard', $stateParams),
            );
        },
    ];
})();
