import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.inventory.content.links').component('inventoryContentLinks', {
        templateUrl: 'app/pages/inventory/content/links/links.html',
        controller: LinksController,
        controllerAs: 'vm',
        bindings: {
            type: '@',
            headline: '@',
            icon: '@',
            active: '@?',
            exportType: '@?',
        },
    });

    LinksController.$inject = [
        'LinksRepo',
        '$controller',
        '$stateParams',
        'ng2SessionService',
        'ng2MonEventsService',
        'gettextCatalog',
        '$filter',
    ];

    function LinksController (LinksRepo, $controller, $stateParams, ng2SessionService, ng2MonEventsService, gettextCatalog, $filter) {
        var vm = this;
        vm.getPage = getPage;
        vm.openDialog = openDialog;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.links = [];
            vm.search = null;
            vm.domain_id = $stateParams.domainId;
            vm.scanDocuments = ng2SessionService.domain.scan.scan_documents;
            vm.subheaderTranslation = '';
            setupTabs();
            getPage();
        }

        function setupTabs () {
            vm.tabs = [
                {
                    name: gettextCatalog.getString('Internal'),
                    value: 'internal',
                },
                {
                    name: gettextCatalog.getString('External'),
                    value: 'external',
                },
            ];
            vm.activeTab = vm.tabs[0].value;
        }

        function getPage () {
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search,
                type: vm.type,
                relation: vm.activeTab,
                page_fixed: false,
            };

            vm.promise = LinksRepo.getAll(params).then(function (data) {
                vm.links = data;
                getSubHeaderDescription();
            }, angular.noop);
        }

        function getSubHeaderDescription () {
            vm.subheaderTranslation = gettextCatalog.getPlural(
                $filter('monNumber')(vm.links.total, '0,0'),
                '1 result',
                '{{$count}} results',
                {},
            );
        }

        function openDialog (link, type) {
            var params = {
                body: 'pagesFilesPages',
                size: 'lg',
                data: { link: link, domainId: vm.domain_id, active: vm.active, icon: vm.icon, type: type },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }
    }
})();
