(function () {
    'use strict';

    angular.module('pages.qa.broken').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.domain.qa.broken',
                config: {
                    url: '/broken',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {
                        sidebar: 'quality',
                    },
                    resolve: {
                        hasAccess: hasAccess,
                    },
                },
            },
            {
                state: 'base.customer.domain.qa.broken.links',
                config: {
                    url: '/links',
                    template: '<broken-links-index></broken-links-index>',
                    data: { title: gettextCatalog.getString('Monsido - Broken Links') },
                },
            },
            {
                state: 'base.customer.domain.qa.broken.images',
                config: {
                    url: '/images',
                    template: '<broken-images-index></broken-images-index>',
                    data: { title: gettextCatalog.getString('Monsido - Broken Images') },
                },
            },
            {
                state: 'base.customer.domain.qa.broken.pages',
                config: {
                    url: '/pages',
                    template: '<broken-pages-index></broken-pages-index>',
                    data: { title: gettextCatalog.getString('Monsido - Content With Broken Links') },
                },
            },
        ];
    }

    var hasAccess = [
        'ng2ActiveFeatureService',
        '$q',
        '$state',
        '$stateParams',
        'ng2MonUIRouterTransitionService',
        function (ng2ActiveFeatureService, $q, $state, $stateParams, ng2MonUIRouterTransitionService) {
            if (ng2ActiveFeatureService.isFeatureActivePure('qa_links')) {
                return $q.resolve(true);
            }
            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.dashboard', $stateParams),
            );
        },
    ];
})();
