import { MON_EVENTS } from '@monsido/core/constants/mon-events.constant';

(function () {
    'use strict';

    angular.module('pages.inventory.email').component('inventoryEmail', {
        templateUrl: 'app/pages/inventory/email/email.html',
        controller: InventoryEmailController,
        controllerAs: 'vm',
    });

    InventoryEmailController.$inject = [
        'LinksRepo',
        'Dialog',
        'ng2SessionService',
        '$controller',
        'ng2MonEventsService',
        'gettextCatalog',
        '$filter',
        'FORM_CONSTANT',
        'ng2ActiveFeatureService',
    ];

    function InventoryEmailController (
        LinksRepo,
        Dialog,
        ng2SessionService,
        $controller,
        ng2MonEventsService,
        gettextCatalog,
        $filter,
        FORM_CONSTANT,
        ng2ActiveFeatureService,
    ) {
        var vm = this;
        vm.getPage = getPage;
        vm.showPages = showPages;
        vm.createEmailPolicy = createEmailPolicy;
        vm.$onInit = activate;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.search = null;
            vm.showPolicy = ng2ActiveFeatureService.isFeatureActivePure('policies_all');
            vm.scanDocuments = ng2SessionService.domain.scan.scan_documents;
            vm.emails = {};
            vm.emails.total = 0;
            vm.currentSort = { direction: 'asc', by: 'url' };
            vm.subheaderTranslation = '';
            vm.exports = [
                {
                    name: gettextCatalog.getString('Start export inventory emails'),
                    data: {
                        category: 'inventory_email',
                    },
                },
                {
                    name: gettextCatalog.getString('Start export inventory emails extended'),
                    data: {
                        category: 'inventory_email_extended',
                    },
                },
            ];
            getPage();
        }

        function getPage () {
            var params = {
                type: 'email',
                page: vm.page,
                page_size: vm.pageSize,
                search: vm.search,
                page_fixed: false,
                sort_dir: vm.currentSort.direction,
                sort_by: vm.currentSort.by,
            };

            vm.promise = LinksRepo.getAll(params).then(function (data) {
                vm.emails = data;
                getSubHeaderDescription();
            }, angular.noop);
        }

        function getSubHeaderDescription () {
            vm.subheaderTranslation = gettextCatalog.getPlural(
                $filter('monNumber')(vm.emails.total, '0,0'),
                '1 result',
                '{{$count}} results',
                {},
            );
        }

        function showPages (email, type) {
            var params = {
                body: 'pagesEmail',
                size: 'md',
                data: { email: email, type: type },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params });
        }

        function createEmailPolicy (email) {
            var params = {
                body: FORM_CONSTANT.FORM_GLOBAL_POLICY,
                size: 'fw',
                data: {
                    policy: {
                        subject: 'link',
                        mode: 'violation',
                        rules: [
                            {
                                case_sensitive: true,
                                inverse: false,
                                multiline: false,
                                name: 'contains the email: ' + email.url,
                                operator: 'contains',
                                query: email.url,
                                sub_entry: '',
                                type: 'link_url',
                            },
                        ],
                    },
                },
            };

            ng2MonEventsService.run(MON_EVENTS.LOAD_DIALOG, { params, callback: getPage });
        }
    }
})();
