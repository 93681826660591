import { skip } from 'rxjs/operators';
(function () {
    'use strict';

    angular.module('app.forms').component('formDomainScan', {
        templateUrl: 'app/forms/domain/steps/scan/scan.html',
        controller: DomainScanController,
        controllerAs: 'vm',
        bindings: {
            domain: '=',
            basicAuthHostValidityChange: '&',
        },
    });

    DomainScanController.$inject = [
        'gettextCatalog',
        'ng2SessionService',
        'ng2DefaultConstraintsAndExcludesService',
        'ng2MonModalService',
        'ng2ActiveFeatureService',
        'ng2DemoHasAccessService',
        'moment',
    ];
    /* @ngInject */
    function DomainScanController (
        gettextCatalog,
        ng2SessionService,
        ng2DefaultConstraintsAndExcludesService,
        ng2MonModalService,
        ng2ActiveFeatureService,
        ng2DemoHasAccessService,
        moment,
    ) {
        const vm = this;
        const defaultText = gettextCatalog.getString('default');
        const defaultTagValue = 'default';
        const labelDeleteConfirmation = gettextCatalog.getString('Are you sure you want to remove the Default label?');
        let pathConstraintsSubscription;
        let linkExcludesSubscription;
        let loadingPathConstraitsProgressSubscription;
        let loadingLinksExcludedProgressSubscription;

        vm.isContstraintsLoading = false;
        vm.isLinkExcludesLoading = false;
        vm.constraintsRightItemLabels = [];
        vm.linkExcludesRightItemLabels = [];
        vm.onDeleteConstraintsDefaultLabelClick = onDeleteConstraintsDefaultLabelClick;
        vm.onDeleteLinksDefaultLabelClick = onDeleteLinksDefaultLabelClick;
        vm.labelTooltipText = gettextCatalog.getString(`Most users of your selected CMS apply this pattern when configuring their domain in Monsido.
        Edit the pattern or remove the Default label to permanently save it for your domain.`);

        vm.$onInit = activate;
        vm.updateNextScanDate = updateNextScanDate;
        vm.setScanInterval = setScanInterval;
        vm.dateTimeFormat = 'lll';

        vm.$onDestroy = destroy;
        vm.onInputValueChanged = onInputValueChanged;

        vm.onBasicAuthHostValidityChange = (valid) => {
            if (vm.basicAuthHostValidityChange) {
                vm.basicAuthHostValidityChange({ valid });
            }
        };

        function activate () {
            vm.customer = ng2SessionService.customer;
            vm.hasCustomScanTrait = ng2ActiveFeatureService.isFeatureActivePure('custom_scan');
            setupDayOptions();
            setupConnectionOptions();
            setupLoginOptions();
            setupIntervalOptions();
            updateScanInterval();
            updateNextScanDate();

            loadingPathConstraitsProgressSubscription = ng2DefaultConstraintsAndExcludesService.loadingPathConstraitsProgress$.subscribe((value) => {
                vm.isContstraintsLoading = value;
            });
            loadingLinksExcludedProgressSubscription = ng2DefaultConstraintsAndExcludesService.loadingLinksExcludedProgress$.subscribe((value) => {
                vm.isLinkExcludesLoading = value;
            });

            // We have to init without default data
            pathConstraintsSubscription = ng2DefaultConstraintsAndExcludesService.defaultPathConstraints$.pipe(skip(1)).subscribe((data) => {
                // We need to wait until vm.domain.cms is updated
                setTimeout(() => {
                    updatePathConstraints(data);
                });
            });
            linkExcludesSubscription = ng2DefaultConstraintsAndExcludesService.defaultLinkExcludes$.pipe(skip(1)).subscribe((data) => {
                // We need to wait until vm.domain.cms is updated
                setTimeout(() => {
                    updateLinkExcludes(data);
                });
            });

            updatePathConstraints([]);
            updateLinkExcludes([]);
            // uib-timepicker interacts with date object
            vm.domain.scan.time = new Date(vm.domain.scan.time);
        }

        function destroy () {
            pathConstraintsSubscription.unsubscribe();
            linkExcludesSubscription.unsubscribe();
            loadingPathConstraitsProgressSubscription.unsubscribe();
            loadingLinksExcludedProgressSubscription.unsubscribe();
        }

        function updateNextScanDate () {
            if (!vm.hasCustomScanTrait && vm.scanIntervalValue !== -1) {
                vm.nextScanDate = getNextScan(vm.domain, vm.scanIntervalValue).format(vm.dateTimeFormat);
            }
        }

        function setScanInterval () {
            if (vm.initialScanIntervalValue !== vm.scanIntervalValue) {
                vm.domain.scan.scan_interval = vm.scanIntervalValue;
                vm.initialScanIntervalValue = vm.scanIntervalValue;
            }
        }

        function updateScanInterval () {
            let interval = vm.customer.plan_traits.scan_interval;
            if (vm.hasCustomScanTrait && vm.domain.scan.scan_interval) {
                interval = vm.domain.scan.scan_interval;
            }
            const scanInterval = vm.scanIntervalOptions.find((option) => (option.value === interval)) || {};
            const scanIntervalValue = scanInterval.value || interval;

            vm.initialScanIntervalValue = vm.scanIntervalValue = scanIntervalValue;
            vm.scanIntervalName = scanInterval.name || gettextCatalog.getString('Custom');
        }

        function setupLoginOptions () {
            vm.loginTypes = [
                {
                    name: gettextCatalog.getString('None'),
                    value: 'none',
                },
                {
                    name: gettextCatalog.getString('Form'),
                    value: 'form',
                },
                {
                    name: gettextCatalog.getString('Office 365 (BETA)'),
                    value: 'office365',
                },
                {
                    name: gettextCatalog.getString('Basic Auth'),
                    value: 'basic_auth',
                },
                {
                    name: gettextCatalog.getString('Custom Multistep'),
                    value: 'custom_multistep',
                },
            ];
        }

        function setupDayOptions () {
            vm.dayOptions = [
                {
                    name: gettextCatalog.getString('Autoselect'),
                    value: 'any',
                },
                {
                    name: gettextCatalog.getString('Sunday'),
                    value: 'sunday',
                },
                {
                    name: gettextCatalog.getString('Monday'),
                    value: 'monday',
                },
                {
                    name: gettextCatalog.getString('Tuesday'),
                    value: 'tuesday',
                },
                {
                    name: gettextCatalog.getString('Wednesday'),
                    value: 'wednesday',
                },
                {
                    name: gettextCatalog.getString('Thursday'),
                    value: 'thursday',
                },
                {
                    name: gettextCatalog.getString('Friday'),
                    value: 'friday',
                },
                {
                    name: gettextCatalog.getString('Saturday'),
                    value: 'saturday',
                },
            ];
        }

        function setupIntervalOptions () {
            vm.scanIntervalOptions = [
                {
                    name: gettextCatalog.getString('Weekly'),
                    value: 7,
                },
                {
                    name: gettextCatalog.getString('Every 2 weeks'),
                    value: 14,
                },
                {
                    name: gettextCatalog.getString('Every 4 weeks'),
                    value: 28,
                },
                {
                    name: gettextCatalog.getString('Every 8 weeks'),
                    value: 56,
                },
                {
                    name: gettextCatalog.getString('Every 12 weeks'),
                    value: 84,
                },
            ];
            if (ng2DemoHasAccessService.isDemoAccount()) {
                vm.scanIntervalOptions.push(
                    {
                        name: gettextCatalog.getString('Never'),
                        value: -1,
                    },
                );
            }
        }

        function setupConnectionOptions () {
            vm.connectionOptions = [
                {
                    name: gettextCatalog.getString('Slow'),
                    value: 30,
                },
                {
                    name: gettextCatalog.getString('Normal - recommended'),
                    value: 60,
                },
                {
                    name: gettextCatalog.getString('Faster'),
                    value: 90,
                },
                {
                    name: gettextCatalog.getString('Very fast - Be Cautious'),
                    value: 120,
                },
                {
                    name: gettextCatalog.getString('Superfast - Don\'t use unless you know what you\'re doing'),
                    value: 180,
                },
            ];
        }

        function onDeleteConstraintsDefaultLabelClick (index) {
            const entry = vm.domain.path_constraints[index];
            ng2MonModalService
                .confirm(labelDeleteConfirmation)
                .then(() => {
                    if (vm.domain.path_constraints.includes(entry) && Array.isArray(entry.tags)) {
                        entry.tags.splice(0, 2);
                        vm.domain.path_constraints = Array.from(vm.domain.path_constraints);
                        vm.constraintsRightItemLabels = makeRightDefaultLabels(vm.domain.path_constraints);
                    }
                }, angular.noop);
        }
        function onDeleteLinksDefaultLabelClick (index) {
            const entry = vm.domain.link_excludes[index];
            ng2MonModalService
                .confirm(labelDeleteConfirmation)
                .then(() => {
                    if (vm.domain.link_excludes.includes(entry) && Array.isArray(entry.tags)) {
                        entry.tags.splice(0, 2);
                        vm.domain.link_excludes = Array.from(vm.domain.link_excludes);
                        vm.linkExcludesRightItemLabels = makeRightDefaultLabels(vm.domain.link_excludes);
                    }
                }, angular.noop);
        }

        function removeUnrelatedEntries (data) {
            return data.filter(entry => {
                if (!entry) {
                    return false;
                }
                if (!Array.isArray(entry.tags)) {
                    return true;
                }
                if (entry.tags[0] === defaultTagValue && entry.tags[1] !== vm.domain.cms) {
                    return false;
                }
                return true;
            });
        }

        function removeNewerDuplicates (data, keyName) {
            const safeData = data.filter(entry => !!entry);

            return safeData
                .map(entry => {
                    return entry[keyName];
                })
                .reduce((acc, current, index, entries) => {
                    if (index === entries.lastIndexOf(current)) {
                        acc.push(safeData[index]);
                    }
                    return acc;
                }, []);
        }

        function makeRightDefaultLabels (items) {
            return items.map(entry => {
                if (entry.tags && entry.tags[0] === defaultTagValue) {
                    return { name: defaultText };
                }
                return undefined;
            });
        }

        function onInputValueChanged (changes, type) {
            const list = type === 'pathConstraints' ? vm.domain.path_constraints : vm.domain.link_excludes;
            const entry = list[changes.index];
            if (Array.isArray(entry.tags) && entry.tags[0] === defaultTagValue) {
                entry.tags.splice(0, 2);
                if (type === 'pathConstraints') {
                    vm.domain.path_constraints = Array.from(list);
                    vm.constraintsRightItemLabels = makeRightDefaultLabels(vm.domain.path_constraints);
                } else {
                    vm.domain.link_excludes = Array.from(list);
                    vm.linkExcludesRightItemLabels = makeRightDefaultLabels(vm.domain.link_excludes);
                }
            }
        }

        function updatePathConstraints (data) {
            if (vm.domain && vm.domain.path_constraints) {
                const pathConstraints = removeUnrelatedEntries(vm.domain.path_constraints);
                vm.domain.path_constraints = removeNewerDuplicates(data.concat(pathConstraints), 'constraint');
                vm.constraintsRightItemLabels = makeRightDefaultLabels(vm.domain.path_constraints);
            }
        }

        function updateLinkExcludes (data) {
            if (vm.domain && vm.domain.link_excludes) {
                const linkExcludes = removeUnrelatedEntries(vm.domain.link_excludes);
                vm.domain.link_excludes = removeNewerDuplicates(data.concat(linkExcludes), 'regex');
                vm.linkExcludesRightItemLabels = makeRightDefaultLabels(vm.domain.link_excludes);
            }
        }

        function getNextScan (domain, scanInterval) {
            const current = moment(domain.last_scan || undefined);
            let weekday = current.day();
            if (domain.scan.day !== 'any') {
                weekday = moment(domain.scan.time).day(domain.scan.day)
                    .utcOffset(0)
                    .isoWeekday();
            }
            const days = (scanInterval + weekday - current.day());
            return current.clone().add(days, 'd')
                .set('hour', moment(domain.scan.time).get('hour'))
                .set('minute', moment(domain.scan.time).get('minute'));
        }
    }
})();
