(function () {
    'use strict';

    angular.module('pages.inventory').run(appRun);

    appRun.$inject = ['routerHelper', 'gettextCatalog'];
    /* @ngInject */
    function appRun (routerHelper, gettextCatalog) {
        routerHelper.configureStates(getStates(gettextCatalog));
    }

    function getStates (gettextCatalog) {
        return [
            {
                state: 'base.customer.domain.inventory',
                config: {
                    url: '/inventory',
                    abstract: true,
                    template: '<div ui-view></div>',
                    data: {
                        sidebar: 'inventory',
                        title: gettextCatalog.getString('Monsido - Inventory'),
                    },
                    resolve: {
                        hasAccess: hasAccess,
                    },
                },
            },
            {
                state: 'base.customer.domain.inventory.dashboard',
                config: {
                    url: '/',
                    template: '<inventory-dashboard></inventory-dashboard>',
                    data: {},
                },
            },
            {
                state: 'base.customer.domain.inventory.email',
                config: {
                    url: '/emails',
                    template: '<inventory-email></inventory-email>',
                    data: { title: gettextCatalog.getString('Monsido - Email addresses') },
                },
            },
        ];
    }

    var hasAccess = [
        'ng2ActiveFeatureService',
        '$q',
        '$state',
        '$stateParams',
        'ng2MonUIRouterTransitionService',
        function (ng2ActiveFeatureService, $q, $state, $stateParams, ng2MonUIRouterTransitionService) {
            if (ng2ActiveFeatureService.isFeatureActivePure('inventory_all')) {
                return $q.resolve(true);
            }
            return ng2MonUIRouterTransitionService.onTransitionFinished(
                () => $state.go('base.customer.domain.dashboard', $stateParams),
            );
        },
    ];
})();
