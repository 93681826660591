(function () {
    'use strict';
    /**
     * @memberof modules.page-performance
     * @ngdoc service
     * @name PagePerformanceService
     * @description Service that contains logical operations used throughout the entire Performance Module
     */
    angular.module('modules.page-performance').service('PagePerformanceService', PagePerformanceService);

    PagePerformanceService.$inject = [
        'ng2SupportRequestRepoService',
        'ng2SessionService',
        'ng2MonModalService',
        'ng2MonEventsService',
        'gettextCatalog',
    ];

    /* @ngInject*/
    function PagePerformanceService (
        ng2SupportRequestRepoService,
        ng2SessionService,
        ng2MonModalService,
        ng2MonEventsService,
        gettextCatalog,
    ) {
        return {
            requestMorePages: requestMorePages,
            requestMoreProfiles: requestMoreProfiles,
            getMaxPageCount: getMaxPageCount,
            getMaxProfileCount: getMaxProfileCount,
            getReachedMaximumProfileMessageDialog: getReachedMaximumProfileMessageDialog,
        };

        /**
         * @memberOf PagePerformanceService
         * @desc Request Support for more Pages to the Customer Account
         * @param msg - Message to inform the Support team about
         */
        function requestMorePages (msg) {
            return requestPerformanceSupport(msg);
        }

        /**
         * @memberOf PagePerformanceService
         * @desc Request Support for more Profiles to the Customer Account
         * @param msg - Message to inform the Support team about
         */
        function requestMoreProfiles (msg) {
            return requestPerformanceSupport(msg);
        }

        /**
         * @memberOf PagePerformanceService
         * @desc Get the number of maximum allowed pages for this account
         */
        function getMaxPageCount () {
            if (ng2SessionService.customer !== null) {
                return ng2SessionService.customer.plan_traits.performance_tracking_max_pages;
            } else {
                return 0;
            }
        }

        /**
         * @memberOf PagePerformanceService
         * @desc Get the number of maximum allowed profiles for this account
         */
        function getMaxProfileCount () {
            if (ng2SessionService.customer !== null && !isNaN(ng2SessionService.customer.plan_traits.performance_tracking_max_profiles)) {
                return ng2SessionService.customer.plan_traits.performance_tracking_max_profiles;
            } else {
                return 0;
            }
        }

        async function getReachedMaximumProfileMessageDialog () {
            return new Promise(resolve => {
                const text = gettextCatalog.getString(
                    'You have reached the maximum number of Performance profiles in your current plan.<br/><br/>' +
                    'To inquire about pricing options for additional profiles, please press the button "Request quote for more profiles" and somone on our team will be in touch.',
                );
                ng2MonEventsService.run('actionTrigger', { action: 'maximum-number-of-profiles-confirm-shown' });
                ng2MonModalService.customDialog({
                    title: '',
                    message: text,
                    buttons: [
                        {
                            label: gettextCatalog.getString('Cancel'),
                            className: 'btn btn-default btn-secondary',
                            callback: () => {
                                ng2MonEventsService.run('actionTrigger', { action: 'maximum-number-of-profiles-confirm-rejected' });
                            },
                        },
                        {
                            label: gettextCatalog.getString('Request quote for more profiles'),
                            className: 'btn btn-mon-primary',
                            callback: () => {
                                resolve();
                            },
                        },
                    ],
                }).catch(() => {});
            });
        }

        // PROTECTED

        /**
         * @memberOf PagePerformanceService
         * @desc Send a post request to the support request endpoint
         * @param msg - Message to inform the Support team about
         */
        function requestPerformanceSupport (msg) {
            var params = {
                code: 'performance',
                message: msg,
            };
            return ng2SupportRequestRepoService.create(params);
        }
    }
})();
