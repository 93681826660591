(function () {
    'use strict';

    angular.module('app.components.pageDetails').component('pageDetailsSectionQaBroken', {
        templateUrl: 'app/components/pagedetails/details/sections/qa/subsections/broken/links.html',
        controller: SectionQpController,
        controllerAs: 'vm',
        bindings: {
            headline: '@',
            icon: '@',
            pageObj: '<',
            type: '<',
            refreshPage: '&',
        },
    });

    SectionQpController.$inject = ['$controller', 'PagePageLinksRepo', 'gettextCatalog', 'QABrokenLinkService'];
    /* @ngInject */
    function SectionQpController ($controller, PagePageLinksRepo, gettextCatalog, QABrokenLinkService) {
        var vm = this;

        vm.getPage = getPage;
        vm.$onInit = activate;
        vm.updateFilters = updateFilters;

        function activate () {
            angular.extend(vm, $controller('BaseApiController', { vm: vm }));
            vm.selected = [];
            vm.pageObj = vm.pageObj || {};
            vm.search = null;
            vm.type = vm.type || 'not_image';
            vm.links = [];
            vm.activeTab = 'dead';
            vm.tabs = [
                { name: gettextCatalog.getString('All'), value: 'dead' },
                { name: gettextCatalog.getString('Ignored'), value: 'ignored' },
                { name: gettextCatalog.getString('Marked as fixed'), value: 'fixed' },
            ];
            vm.isFiltersOpen = false;
            vm.filters = { type: [], 'response code': [] };
            vm.availableFilters = {
                type: [{ id: 'html', name: 'Pages' }, { id: 'document', name: 'Documents' }],
                'response code': QABrokenLinkService.getResponseCodes(),
            };
            vm.exports = [
                {
                    name:
                        vm.type === 'not_image'
                            ? gettextCatalog.getString('Broken links')
                            : gettextCatalog.getString('Broken images'),
                    data: {
                        category: vm.type === 'not_image' ? 'dead_links' : 'dead_images',
                        category_ref: vm.pageObj.id,
                    },
                },
            ];

            getPage();
        }

        function getPage () {
            vm.selected = [];
            var params = {
                page: vm.page,
                page_size: vm.pageSize,
                status: vm.activeTab,
                type: vm.type,
                search: vm.search,
            };
            if (vm.filters.type.length) {
                params.page_type = vm.filters.type[0].id;
            }
            if (vm.filters['response code'].length) {
                params.status_code = vm.filters['response code'][0].id;
            }

            params.page_fixed = false;

            vm.promise = PagePageLinksRepo.getAll(vm.pageObj.id, params).then(function (data) {
                vm.links = data;
            }, angular.noop);
        }

        function updateFilters (changes) {
            if (changes.type.length === 1) {
                vm.filters.type = changes.type;
            } else if (changes.type.length > 1) {
                vm.filters.type.splice(0, 1);
            } else {
                vm.filters.type = [];
            }
            if (changes['response code'].length === 1) {
                vm.filters['response code'] = changes['response code'];
            } else if (changes['response code'].length > 1) {
                vm.filters['response code'].splice(0, 1);
            } else {
                vm.filters['response code'] = [];
            }
            vm.getPage();
        }
    }
})();
